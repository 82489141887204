/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../_modules/tabs/tabs.component.ngfactory";
import * as i3 from "../_modules/tabs/tabs.component";
import * as i4 from "./info-tabs.component";
import * as i5 from "../_services/landing.service";
import * as i6 from "@ngx-translate/core";
var styles_InfoTabsComponent = [i0.styles];
var RenderType_InfoTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoTabsComponent, data: {} });
export { RenderType_InfoTabsComponent as RenderType_InfoTabsComponent };
export function View_InfoTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "info-tabs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-tabs", [], null, null, null, i2.View_TabsComponent_0, i2.RenderType_TabsComponent)), i1.ɵdid(2, 573440, null, 0, i3.TabsComponent, [], { items: [0, "items"], activeIndex: [1, "activeIndex"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoTabsItems; var currVal_1 = 0; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_InfoTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-tabs", [], null, null, null, View_InfoTabsComponent_0, RenderType_InfoTabsComponent)), i1.ɵdid(1, 114688, null, 0, i4.InfoTabsComponent, [i5.LandingService, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoTabsComponentNgFactory = i1.ɵccf("app-info-tabs", i4.InfoTabsComponent, View_InfoTabsComponent_Host_0, {}, {}, []);
export { InfoTabsComponentNgFactory as InfoTabsComponentNgFactory };
