/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./workshops-accordion.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-page-scroll/src/ngx-page-scroll.directive";
import * as i3 from "ngx-page-scroll/src/ngx-page-scroll.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./workshops-accordion.component";
import * as i8 from "../../_services/landing.service";
var styles_WorkshopsAccordionComponent = [i0.styles];
var RenderType_WorkshopsAccordionComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_WorkshopsAccordionComponent, data: { "animation": [{ type: 7, name: "slideToggle", definitions: [{ type: 0, name: "*", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { height: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void <=> *", animation: { type: 4, styles: null, timings: 300 }, options: null }], options: {} }] } });
export { RenderType_WorkshopsAccordionComponent as RenderType_WorkshopsAccordionComponent };
function View_WorkshopsAccordionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "btn-secondary float-right"], ["href", "#workshopTable"], ["pageScroll", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.highlightWorkshop(_v.parent.parent.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.PageScroll, [i3.PageScrollService, [2, i4.Router], i5.DOCUMENT], { href: [0, "href"], pageScrollDuration: [1, "pageScrollDuration"], pageScroll: [2, "pageScroll"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "#workshopTable"; var currVal_1 = 400; var currVal_2 = ""; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("buttons.view_dates")); _ck(_v, 2, 0, currVal_3); }); }
function View_WorkshopsAccordionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "accordion_content"]], [[24, "@slideToggle", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "accordion_inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopsAccordionComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showHighlightWorkshopButton; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit == _co.activeWorkshop); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.description; _ck(_v, 2, 0, currVal_1); }); }
function View_WorkshopsAccordionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "accordion_item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "-active": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "accordion_link"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "accordion_indicator"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopsAccordionComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "accordion_item"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit == _co.activeWorkshop)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (_v.context.$implicit == _co.activeWorkshop); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
export function View_WorkshopsAccordionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "accordion"], ["data-module", "accordion"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkshopsAccordionComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workshops; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WorkshopsAccordionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workshops-accordion", [], null, null, null, View_WorkshopsAccordionComponent_0, RenderType_WorkshopsAccordionComponent)), i1.ɵdid(1, 573440, null, 0, i7.WorkshopsAccordionComponent, [i8.LandingService], null, null)], null, null); }
var WorkshopsAccordionComponentNgFactory = i1.ɵccf("app-workshops-accordion", i7.WorkshopsAccordionComponent, View_WorkshopsAccordionComponent_Host_0, { workshops: "workshops" }, {}, []);
export { WorkshopsAccordionComponentNgFactory as WorkshopsAccordionComponentNgFactory };
