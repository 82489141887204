/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sidebar/sidebar.component.ngfactory";
import * as i3 from "./sidebar/sidebar.component";
import * as i4 from "../_services/landing.service";
import * as i5 from "./content/content.component.ngfactory";
import * as i6 from "./content/content.component";
import * as i7 from "../_services/access.service";
import * as i8 from "./landing.component";
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LandingComponent, data: {} });
export { RenderType_LandingComponent as RenderType_LandingComponent };
export function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "main-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "heading -h1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "container -flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "aside", [["app-sidebar", ""]], null, null, null, i2.View_SidebarComponent_0, i2.RenderType_SidebarComponent)), i1.ɵdid(7, 49152, null, 0, i3.SidebarComponent, [i4.LandingService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "main", [["app-content", ""]], null, null, null, i5.View_ContentComponent_0, i5.RenderType_ContentComponent)), i1.ɵdid(9, 49152, null, 0, i6.ContentComponent, [i7.AccessService, i4.LandingService], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.landing.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 245760, null, 0, i8.LandingComponent, [i4.LandingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingComponentNgFactory = i1.ɵccf("app-landing", i8.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
export { LandingComponentNgFactory as LandingComponentNgFactory };
