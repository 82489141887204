<div class="page-message" *ngIf="state === 'error'; else successBlock">
    <h1 class="heading page-message_heading">
        {{'messages.error_header' | translate}}
    </h1>

    <ng-container [ngSwitch]="type">
        <p class="page-message_text" *ngSwitchCase="'cancellation'">
            {{'messages.error_cancellation_body' | translate}}
        </p>
        <p class="page-message_text" *ngSwitchDefault>
            {{'messages.error_body' | translate}}
        </p>
    </ng-container>

</div>

<ng-template #successBlock>
    <div class="page-message" [ngSwitch]="type">
        <p class="page-message_text" *ngSwitchCase="'subscription'">
            {{'messages.success_subscription_body' | translate}}
        </p>
        <p class="page-message_text" *ngSwitchCase="'information'">
            {{'messages.success_information_body' | translate}}
        </p>
        <p class="page-message_text" *ngSwitchCase="'cancellation'">
            {{'messages.success_cancellation_body' | translate}}
        </p>
    </div>
</ng-template>