import { NgModule } from "@angular/core";
import { HeaderComponent } from "./header/header.component";
import { LanguageSelectComponent } from "./header/language-select/language-select.component";
import { LanguageService } from "../_services/language.service";
import { SharedModule } from "../shared.module";
import { Page404Component } from "./page-404/page-404.component";
import { ApiService } from "../_services/api.service";
import { LogoComponent } from './header/logo/logo.component';
import { LogoService } from "./header/logo/logo.service";
import { RouterModule } from "@angular/router";
import { FooterComponent } from './footer/footer.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LanguageSelectComponent,
        Page404Component,
        LogoComponent
    ],
    imports: [
        RouterModule,
        SharedModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
    ],
    providers: [
        LanguageService,
        ApiService,
        LogoService
    ]
})

export class CoreModule {
}
