<ul class="accordion" data-module="accordion">
    <li class="accordion_item"
        *ngFor="let workshop of workshops"
        [ngClass]="{'-active': workshop == activeWorkshop}"
        (click)="onClick(workshop)"
    >
        <p class="accordion_link">{{workshop.title}}
            <span class="accordion_indicator"></span>
        </p>
        <div class="accordion_content"
             *ngIf="workshop == activeWorkshop"
             [@slideToggle]="workshop == activeWorkshop">
            <div class="accordion_inner">
                <div [innerHtml]="workshop.description"></div>
                <a *ngIf="showHighlightWorkshopButton" pageScroll [pageScrollDuration]="400" href="#workshopTable" class="btn-secondary float-right" (click)="highlightWorkshop(workshop.id)">
                    {{'buttons.view_dates' | translate}}
                </a>
            </div>
        </div>
    </li>
</ul>
