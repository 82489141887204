import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { Workshop } from "../../_models/workshop.model";
import { LandingService } from "../../_services/landing.service";


@Component({
    selector: 'app-workshops-accordion',
    templateUrl: './workshops-accordion.component.html',
    styleUrls: ['./workshops-accordion.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('slideToggle', [
            state('*', style({
                height: '*'
            })),
            state('void', style({
                height: 0
            })),
            transition('void <=> *', animate(300))
        ]),
    ]
})
export class WorkshopsAccordionComponent implements OnChanges {

    @Input('workshops') workshops: Workshop[] = [];
    activeWorkshop: Workshop;
    showHighlightWorkshopButton = false;

    constructor(private $landing: LandingService) {
        if (this.$landing.landing.isFullSubscription) {
            this.showHighlightWorkshopButton = true;
        }
    }

    onClick(workshop: Workshop){
        this.activeWorkshop = workshop;
    }

    ngOnChanges(){
        this.activeWorkshop = this.workshops[0];
    }

    highlightWorkshop(workshopId){
        this.$landing.onHighlightedWorkshopChange.next(workshopId);
    }
}
