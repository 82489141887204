import { Training } from "./training.model";
import * as _ from 'underscore';

export class Workshop {
    id: string;
    title: string;
    shortTitle: string;
    description: string;
    price: number;
    priceType: number;
    free: boolean;
    sortOrder: number;
    showIfRestIsFull: boolean;
    trainings: Training[] = [];

    constructor(data: any){
        this.id = data.id;
        this.title = data.title;
        this.shortTitle = data.short_title;
        this.description = data.description;
        this.price = data.price;
        this.priceType = data.price_type;
        this.free = data.free;
        this.sortOrder = data.sort_order;
        this.showIfRestIsFull = data.show_if_rest_is_full;
        this.trainings = Training.collection(data.events, this);
    }

    static collection(workshops: any[]): Workshop[]{
        if (workshops) {
            return _.sortBy(workshops.map(workshop => new Workshop(workshop)), (workshop) => {
                return workshop.sortOrder || workshop.title.toLowerCase();
            });
        }
    }
}