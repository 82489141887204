import {
    Component, ElementRef, Input, ViewChild, OnChanges, SimpleChanges, Output,
    EventEmitter, ViewEncapsulation, Renderer2
} from '@angular/core';
import { SelectOption } from "./option.model";

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    host: {
        '(document:click)': 'onOutsideClick($event)',
        '(document:keyup)': 'onKeyup($event)',
        '(document: keypress)': 'onKeypress($event)'
    },
    styleUrls: ['./select.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnChanges {

    options: SelectOption[] = [];
    selectedOptions: SelectOption[] = [];
    showedOptions: SelectOption[] = [];

    hoveredOption: SelectOption;
    dropdownIsOpened: boolean = false;
    isTouchDevice = ('ontouchstart' in window ) || navigator.maxTouchPoints;

    @ViewChild('input') input: ElementRef;
    @ViewChild('dropdown') dropdown: ElementRef;

    @Input('options') items: any[] = [];
    @Input('disabled') isDisabled: boolean = false;
    @Input('selected') selectedItems: any[] = [];
    @Input('placeholder') placeholder: string = '';
    @Input('textProperty') textProperty: string = 'text';
    @Input('emptyText') emptyText: string = '';
    @Input('multiple') isMultiple: boolean = false;
    @Output() onChange = new EventEmitter<any>();

    constructor(private selectRef: ElementRef){
    }


    onOutsideClick(event){
        if (!this.selectRef.nativeElement.contains(event.target) && this.dropdownIsOpened){
            this.closeDropdown();
        }
    }

    onKeypress(event){
        if (this.dropdownIsOpened && (event.keyCode == '13' || event.keyCode == '27')) {
            this.selectHoveredOption(event);
            event.preventDefault();
            return false;
        }
    }

    onKeyup(event){
        if (this.dropdownIsOpened) {
            switch (event.keyCode) {
                case 40:
                    this.hoverNextOption();
                    break;
                case 38:
                    this.hoverPrevOption();
                    break;
                default:
                    this.filterOptions();
            }
        }
    }

    ngOnChanges(changes: SimpleChanges){
        if (changes.hasOwnProperty('items') || changes.hasOwnProperty('selectedItems')) {
            this.init();
        }
    }

    init(){
        this.setOptions();
        this.setInputValue();
    }

    setOptions(){
        this.selectedOptions = [];
        this.options = this.items.map((initialOption) =>{
            let option = new SelectOption(initialOption, this.textProperty);
            this.selectedItems.forEach((preselectedOption) =>{
                if (JSON.stringify(initialOption) === JSON.stringify(preselectedOption)) {
                    option.selected = true;
                    this.selectedOptions.push(option);
                }
            });
            return option;
        });
    }


    onOptionRemove(event, option){
        this.removeOption(option);
        event.stopPropagation();
    }

    removeOption(option){
        option.selected = false;
        let index = this.selectedOptions.indexOf(option);
        this.selectedOptions.splice(index, 1);
        this.triggerChange();
    }

    selectOption(option){
        if (!this.isMultiple)
            this.deselectOptions();

        this.selectedOptions.push(option);
        option.selected = true;
        this.triggerChange();

    }

    selectHoveredOption(event){
        (this.hoveredOption)
            ? this.onOptionClick(event, this.hoveredOption)
            : this.closeDropdown();
    }

    triggerChange(){
        (this.isMultiple)
            ? this.onChange.next(this.selectedOptions.map(option => option.initialOption))
            : this.onChange.next(this.selectedOptions[0].initialOption);
    }

    deselectOptions(){
        this.selectedOptions = [];
        this.options.forEach(option =>{
            option.selected = false;
        });
    }

    onOptionClick(event, option){
        (option.selected && this.isMultiple)
            ? this.removeOption(option)
            : this.selectOption(option);

        this.closeDropdown();
        event.stopPropagation();
    }

    onOptionHover(option){
        this.hoveredOption = option;
    }

    onSelectClick(){
        this.input.nativeElement.value = '';
        this.openDropdown();
    }

    openDropdown(){
        this.showedOptions = this.options;
        this.hoveredOption = this.showedOptions[0];
        this.dropdownIsOpened = true;
    }

    closeDropdown(){
        this.setInputValue();
        this.blurInput();
        this.dropdownIsOpened = false;
    }

    setInputValue(){
        (this.selectedOptions.length && !this.isMultiple)
            ? this.input.nativeElement.value = this.selectedOptions[0].text
            : this.setPlaceholder();
    }

    setPlaceholder(){
        this.input.nativeElement.value = this.placeholder ? this.placeholder : '';
    }

    filterOptions(){
        let query = this.input.nativeElement.value.toString().toLocaleLowerCase().replace(/\\/, /\\/);
        this.showedOptions = this.options.filter(option =>{
            let matched = option.text.toString().toLocaleLowerCase().match(query);
            return matched && matched.index == 0;
        });
        this.hoveredOption = this.showedOptions[0];
    }


    blurInput(){
        this.input.nativeElement.blur();
    }

    hoverPrevOption(){
        let index = this.showedOptions.indexOf(this.hoveredOption);
        if (index != 0)
            this.hoveredOption = this.showedOptions[index - 1];

        this.handleDropdownScroll('up');
    }

    hoverNextOption(){
        let index = this.showedOptions.indexOf(this.hoveredOption);
        if (index != this.showedOptions.length - 1)
            this.hoveredOption = this.showedOptions[index + 1];


        this.handleDropdownScroll('down');
    }

    handleDropdownScroll(direction: string){

        let $dropdown = this.dropdown.nativeElement,
            $hoveredOption = this.dropdown.nativeElement.querySelector('.-hover');

        const OPTION_HEIGHT = $hoveredOption.offsetHeight,
            OPTION_TOP = $hoveredOption.getBoundingClientRect().top - OPTION_HEIGHT,
            OPTION_BOTTOM = $hoveredOption.getBoundingClientRect().bottom + OPTION_HEIGHT,
            DROPDOWN_TOP = $dropdown.getBoundingClientRect().top,
            DROPDOWN_BOTTOM = $dropdown.getBoundingClientRect().bottom;

        if (OPTION_BOTTOM > DROPDOWN_BOTTOM && direction == 'down') {
            $dropdown.scrollTop += OPTION_HEIGHT;
        }

        if (OPTION_TOP < DROPDOWN_TOP && direction == 'up') {
            $dropdown.scrollTop -= OPTION_HEIGHT;
        }
    }
}
