<div class="main-title">
    <div class="container">
        <h1 class="heading -h1">
            {{'headers.workshop_cancel' | translate}}
        </h1>
    </div>
</div>
<div class="main-content">
    <div class="container">
      <h3 class="heading -h3">
        {{'content_headers.workshop_cancel' | translate}}
      </h3>
        <div class="workshop-table">
            <div class="workshop-table_header">
                <span class="workshop-cell">{{'workshop_table.date' | translate}}</span>
                <span class="workshop-cell">{{'workshop_table.time' | translate}}</span>
                <span class="workshop-cell -large">{{'workshop_table.workshop' | translate}}</span>
                <span class="workshop-cell">{{'workshop_table.price' | translate}}</span>
                <span class="workshop-cell">{{'workshop_table.location' | translate}}</span>
                <span class="workshop-cell">{{'workshop_table.attendee' | translate}}</span>
            </div>
            <ul class="workshop-table_body">
                <li *ngFor="let training of trainings"
                    [ngClass]="{'-selected': training.selected}"
                    class="workshop-row"
                    (click)="onTrainingSelect(training)">
                    <span class="checkbox">
                        <input type="checkbox" [(ngModel)]="training.selected">
                        <span class="checkbox_square"></span>
                    </span>
                    <span class="workshop-cell -left">
                        {{'weekdays.' + training.startWeekday | translate}}
                        <br>
                        {{training.dateStart | date: 'dd-MM-yyyy'}}
                        <ng-container *ngIf="training.dateStart.getTime() !== training.dateEnd.getTime()">
                            <br>
                            {{'weekdays.' + training.endWeekday | translate}}
                            <br>
                            {{training.dateEnd | date: 'dd-MM-yyyy'}}
                        </ng-container>
                    </span>
                    <span class="workshop-cell -right">
                        {{training.timeStart}}
                        <br>
                        {{training.timeEnd}}
                    </span>
                    <span class="workshop-cell -large -left">
                        {{training.shortTitle}}
                    </span>
                    <span class="workshop-cell -right -nowrap">
                        € {{training.price}}
                        <ng-container *ngIf="training.priceType === 0">
                            ,- p.p
                        </ng-container>
                        <ng-container *ngIf="training.priceType === 1">
                            ,- p.t
                        </ng-container>
                    </span>
                    <span class="workshop-cell -left">
                        {{training.city}}
                    </span>
                    <span class="workshop-cell -right">
                        <ul *ngIf="training.attendees.length">
                            <li *ngFor="let attendee of training.attendees">{{attendee}}</li>
                        </ul>
                    </span>
                </li>
            </ul>
        </div>
        <button class="btn-primary -large -arrow" (click)="submit()" [disabled]="!selectedIds.length || submitting">
            {{'buttons.cancel_subscription' | translate}}
        </button>
    </div>
</div>
