/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./logo.component";
import * as i5 from "./logo.service";
var styles_LogoComponent = [i0.styles];
var RenderType_LogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
function View_LogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "landing-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.$logo.logoSrc; _ck(_v, 1, 0, currVal_0); }); }
function View_LogoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "header_logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "logo-image"], ["src", "../../assets/img/3m-logo.png"], ["srcset", "../../assets/img/3m-logo_2x.png 2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["class", "logo-image -mobile"], ["src", "../../assets/img/3m-logo-mobile.png"], ["srcset", "../../assets/img/3m-logo-mobile_2x.png 2x"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.$logo.logoLink; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_LogoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "header_logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "logo-image"], ["src", "../../assets/img/3m-logo.png"], ["srcset", "../../assets/img/3m-logo_2x.png 2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "logo-image -mobile"], ["src", "../../assets/img/3m-logo-mobile.png"], ["srcset", "../../assets/img/3m-logo-mobile_2x.png 2x"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.$logo.logoLink; _ck(_v, 1, 0, currVal_0); }); }
function View_LogoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogoComponent_3)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["blankLogoLink", 2]], null, 0, null, View_LogoComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.$logo.logoLink === "/"); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogoComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["defaultLogo", 2]], null, 0, null, View_LogoComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.$logo.logoSrc; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logo", [], null, null, null, View_LogoComponent_0, RenderType_LogoComponent)), i1.ɵdid(1, 114688, null, 0, i4.LogoComponent, [i5.LogoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoComponentNgFactory = i1.ɵccf("app-logo", i4.LogoComponent, View_LogoComponent_Host_0, {}, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
