/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "./workshops/workshop-table/workshop-table.component.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./core/header/header.component.ngfactory";
import * as i4 from "./core/header/header.component";
import * as i5 from "@angular/router";
import * as i6 from "../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i7 from "ngx-loading";
import * as i8 from "./core/footer/footer.component.ngfactory";
import * as i9 from "./core/footer/footer.component";
import * as i10 from "./app.component";
import * as i11 from "./_services/api.service";
var styles_AppComponent = [i0.styles, i1.styles];
var RenderType_AppComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i2.ɵdid(1, 49152, null, 0, i4.HeaderComponent, [], null, null), (_l()(), i2.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i2.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i2.ViewContainerRef, i2.ComponentFactoryResolver, [8, null], i2.ChangeDetectorRef], null, null), (_l()(), i2.ɵeld(4, 0, null, null, 2, "ngx-loading", [], null, null, null, i6.View_ɵb_0, i6.RenderType_ɵb)), i2.ɵdid(5, 114688, null, 0, i7.ɵb, [i7.ɵc], { show: [0, "show"], config: [1, "config"] }, null), i2.ɵpod(6, { backdropBorderRadius: 0 }), (_l()(), i2.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i2.ɵdid(8, 49152, null, 0, i9.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.loading; var currVal_1 = _ck(_v, 6, 0, "14px"); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i2.ɵdid(1, 245760, null, 0, i10.AppComponent, [i11.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i2.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
