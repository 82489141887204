export class Language {
    name: string;
    code: string;
    flag: string;

    constructor(data: any){
        this.name = data.name;
        this.code = data.code;
        this.flag = `../../assets/img/flags/${this.code}.svg`
    }
}