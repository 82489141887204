<div *ngIf="!isExtended" [formGroup]="formGroup">
    <div class="company">
        <div formGroupName="legal">
            <div class="form-line">
                <div class="form-group">
                    <input formControlName="name" [placeholder]="(slug === '/oralcare' ? 'fields.practice_name' : 'fields.company_name') | translate" type="text">
                </div>
            </div>
            <div class="form-line">
                <div class="form-group">
                    <input formControlName="email" [placeholder]="'fields.company_email' | translate" type="email">
                    <span class="form-error-msg" *ngIf="formGroup.get('legal.email').value && !formGroup.get('legal.email').valid">
                        {{'errors.invalid_email' | translate}}
                    </span>
                </div>
                <div class="form-group">
                    <input formControlName="phoneNumber" [placeholder]="'fields.company_phone' | translate" type="text">
                </div>
            </div>
            <div class="form-line">
                <div class="form-group company-street">
                    <input formControlName="street" [placeholder]="'fields.street' | translate" type="text">
                </div>
                <div class="form-group company-building">
                    <input formControlName="building" [placeholder]="'fields.number' | translate" type="text">
                </div>
                <div class="form-group company-zip">
                    <input formControlName="postalCode" [placeholder]="'fields.zipcode' | translate" type="text">
                </div>
            </div>
            <div class="form-line">
                <div class="form-group">
                    <input formControlName="city" [placeholder]="'fields.city' | translate" type="text">
                </div>
                <div class="form-group">
                    <input type="hidden" formControlName="country">
                    <app-select [placeholder]="'fields.country' | translate"
                                emptyText="No results"
                                textProperty="name"
                                [selected]="[selectedCountry]"
                                [options]="landing.countries"
                                (onChange)="onSelectCountry($event, 'legal')">
                    </app-select>
                </div>
            </div>
            <div class="form-line">
                <div class="form-group">
                    <input formControlName="VATNumber" [placeholder]="('fields.vat_number' | translate) + (landing.isVatRequired ? '*' : '') + ' ' + (this.vatMask.legal ? this.vatMask.legal : '')" type="text">
                    <span class="form-error-msg" *ngIf="formGroup.get('legal.VATNumber').value && !formGroup.get('legal.VATNumber').valid">
                        {{'errors.invalid_vat' | translate}}
                    </span>
                </div>
            </div>
            <ng-container *ngIf="formGroup.get('legal.meta')">
                <div class="form-line" formGroupName="meta">
                    <div class="form-group" *ngIf="formGroup.get('legal.meta.bigNumber')">
                        <input formControlName="bigNumber" [placeholder]="'fields.big_number' | translate" type="text">
                    </div>
                    <div class="form-group" *ngIf="formGroup.get('legal.meta.rizivNumber')">
                        <input formControlName="rizivNumber" [placeholder]="'fields.riziv_number' | translate" type="text">
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-line">
            <div class="form-group">
                <p [innerHtml]="(slug === '/oralcare' ? 'labels.dutch_vat_oralcare': 'labels.dutch_vat') | translate">
                </p>
            </div>
        </div>
        <div class="form-line">
            <div class="form-group" [ngSwitch]="slug">
                <p *ngSwitchCase="'/oralcare'" [innerHtml]="'labels.no_vat_oralcare' | translate"></p>
                <p *ngSwitchCase="'/orthodontics'" [innerHtml]="'labels.no_vat_orthodontics' | translate"></p>
                <p *NgSwitchDefault [innerHtml]="'labels.no_vat' | translate"></p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isExtended" [formGroup]="$form.form" class="customer-reference-number">
    <div class="form-line">
        <div class="form-group">
            <input formControlName="customerReferenceNumber" [placeholder]="'fields.customer_reference_number' | translate" type="text">
        </div>
    </div>
    <div class="form-line">
        <div class="form-group">
            <p>{{ 'labels.customer_reference_number' | translate }}</p>
        </div>
    </div>
</div>
<div *ngIf="!isExtended" [formGroup]="formGroup">
    <div formGroupName="billing" class="company_billing-address" *ngIf="isBillingAddress" [@slideToggle]="isBillingAddress">
        <h3 class="heading -h6">
            {{'headers.billing_address' | translate}}
        </h3>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="name" [placeholder]="(slug === '/oralcare' ? 'fields.practice_name' : 'fields.company_name') | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="email" [placeholder]="'fields.company_email' | translate" type="email">
                <span class="form-error-msg" *ngIf="formGroup.get('billing.email').value && !formGroup.get('billing.email').valid">
                    {{'errors.invalid_email' | translate}}
                </span>
            </div>
            <div class="form-group">
                <input formControlName="phoneNumber" [placeholder]="'fields.company_phone' | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group company-street">
                <input formControlName="street" [placeholder]="'fields.street' | translate" type="text">
            </div>
            <div class="form-group company-building">
                <input formControlName="building" [placeholder]="'fields.number' | translate" type="text">
            </div>
            <div class="form-group company-zip">
                <input formControlName="postalCode" [placeholder]="'fields.zipcode' | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="city" [placeholder]="'fields.city' | translate" type="text">
            </div>
            <div class="form-group">
                <input type="hidden" formControlName="country">
                <app-select [placeholder]="'fields.country' | translate"
                            textProperty="name"
                            [options]="landing.countries"
                            emptyText="No results"
                            (onChange)="onSelectCountry($event, 'billing')">
                </app-select>
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="VATNumber" [placeholder]="('fields.vat_number' | translate) + (landing.isVatRequired ? '*' : '') + ' ' + (this.vatMask.billing ? this.vatMask.billing : '')" type="text">
                <span class="form-error-msg" *ngIf="formGroup.get('billing.VATNumber').value && !formGroup.get('billing.VATNumber').valid">
                    {{'errors.invalid_vat' | translate}}
                </span>
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <p [innerHtml]="(slug === '/oralcare' ? 'labels.dutch_vat_oralcare': 'labels.dutch_vat') | translate">
                </p>
            </div>
        </div>
        <div class="form-line">
            <div class="form-group" [ngSwitch]="slug">
                <p *ngSwitchCase="'/oralcare'" [innerHtml]="'labels.no_vat_oralcare' | translate"></p>
                <p *ngSwitchCase="'/orthodontics'" [innerHtml]="'labels.no_vat_orthodontics' | translate"></p>
                <p *NgSwitchDefault [innerHtml]="'labels.no_vat' | translate"></p>
            </div>
        </div>
    </div>
    <button type="button" class="btn-add" (click)="toggleBillingAddress()" [ngClass]="{'-active': isBillingAddress}">
        <span *ngIf="!isBillingAddress">
            {{'buttons.add_billing' | translate}}
        </span>
        <span *ngIf="isBillingAddress">
            {{'buttons.remove_billing' | translate}}
        </span>
        <span class="btn-add_indicator"></span>
    </button>
</div>

<div *ngIf="isExtended" [formGroup]="formGroup">
    <div class="company">
        <div class="form-line">
            <div class="form-group">
                <input formControlName="name" [placeholder]="(slug === '/oralcare' ? 'fields.practice_name' : 'fields.company_name') | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="bookerFirstName" [placeholder]="'fields.first_name' | translate" type="text">
            </div>
            <div class="form-group">
                <input formControlName="bookerMiddleName" [placeholder]="'fields.second_name' | translate" type="text">
            </div>
            <div class="form-group">
                <input formControlName="bookerLastName" [placeholder]="'fields.last_name' | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <label class="radio">
                    <input formControlName="bookerGender" type="radio" value="1">
                    <span class="radio_circle"></span>
                    <span class="radio_value">{{'fields.male' | translate}}</span>
                </label>
                <label class="radio">
                    <input formControlName="bookerGender" type="radio" value="0">
                    <span class="radio_circle"></span>
                    <span class="radio_value">{{'fields.female' | translate}}</span>
                </label>
            </div>
            <div class="form-group">
                <input formControlName="bookerPosition" [placeholder]="'fields.booker_position' | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group company-street">
                <input formControlName="street" [placeholder]="'fields.street' | translate" type="text">
            </div>
            <div class="form-group company-building">
                <input formControlName="building" [placeholder]="'fields.number' | translate" type="text">
            </div>
            <div class="form-group company-zip">
                <input formControlName="postalCode" [placeholder]="'fields.zipcode' | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="city" [placeholder]="'fields.city' | translate" type="text">
            </div>
            <div class="form-group">
                <input type="hidden" formControlName="country">
                <app-select [placeholder]="'fields.country' | translate"
                            emptyText="No results"
                            textProperty="name"
                            [options]="landing.countries"
                            (onChange)="onSelectCountry($event)">
                </app-select>
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="bookerEmail" [placeholder]="'fields.booker_email' | translate" type="email">
                <span class="form-error-msg" *ngIf="formGroup.get('bookerEmail').value && !formGroup.get('bookerEmail').valid">
                    {{'errors.invalid_email' | translate}}
                </span>
            </div>
            <div class="form-group">
                <input formControlName="bookerPhoneNumber" [placeholder]="'fields.booker_phone' | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <input type="hidden" formControlName="workshops">
                <app-select [placeholder]="'fields.workshops' | translate"
                            [multiple]="true"
                            emptyText="No results"
                            textProperty="title"
                            [options]="landing.workshops"
                            (onChange)="onSelectWorkshop($event)">
                </app-select>
            </div>
        </div>
        <div class="multiple-datepicker" [ngClass]="{'ng-invalid': formGroup.get('dates').hasError('dates')}">
            <div class="form-line" *ngFor="let date of dates">
                <div class="form-group">
                    <input class="multiple-datepicker_input" [formControl]="date" matInput [matDatepicker]="picker" (dateChange)="onSelectDates()" [placeholder]="'fields.date' | translate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
            </div>
            <span class="form-error-msg" *ngIf="formGroup.get('dates').hasError('dates')">
                {{'errors.select_date' | translate}}
            </span>
        </div>
        <div class="form-line">
            <div class="form-group">
                <button type="button" class="btn-add" (click)="addDate()">
                    {{'buttons.add_date' | translate}}
                    <span class="btn-add_indicator"></span>
                </button>
            </div>
        </div>
        <h3 class="heading -h6">
            {{'headers.course_location' | translate}}:
        </h3>
        <div class="form-line">
            <div class="form-group">
                <label class="radio" *ngFor="let location of landing.courseLocations">
                    <input formControlName="location" type="radio" [value]="location.id">
                    <span class="radio_circle"></span>
                    <span class="radio_value">{{location.description}}</span>
                </label>
            </div>
        </div>
        <hr>
        <div class="form-line">
            <div class="form-group">
                <input formControlName="peopleAmount" [placeholder]="peopleAmountPlaceholder ? peopleAmountPlaceholder : 'fields.people_amount' | translate" type="text">
            </div>
        </div>
        <div class="form-line">
            <div class="form-group">
                <textarea rows="5" formControlName="infoQuestion" [placeholder]="infoQuestionPlaceholder ? infoQuestionPlaceholder : 'fields.info_question' | translate" type="text"></textarea>
            </div>
        </div>
    </div>
</div>
