/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logo/logo.component.ngfactory";
import * as i3 from "./logo/logo.component";
import * as i4 from "./logo/logo.service";
import * as i5 from "./language-select/language-select.component.ngfactory";
import * as i6 from "./language-select/language-select.component";
import * as i7 from "../../_services/language.service";
import * as i8 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-logo", [], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(3, 114688, null, 0, i3.LogoComponent, [i4.LogoService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-language-select", [["class", "header_language"]], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).closeSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_LanguageSelectComponent_0, i5.RenderType_LanguageSelectComponent)), i1.ɵdid(5, 49152, null, 0, i6.LanguageSelectComponent, [i1.ElementRef, i7.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i8.HeaderComponent, [], null, null)], null, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i8.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
