import { Injectable } from '@angular/core';
import { Landing } from "../_models/landing.model";
import { Title } from "@angular/platform-browser";
import { LanguageService } from "./language.service";
import { ApiService } from "./api.service";
import { BehaviorSubject, Subject, Subscription } from "rxjs";

@Injectable()
export class LandingService {
    landing: Landing;
    onLandingChange = new Subject<Landing>();
    onHighlightedWorkshopChange = new Subject<string>();
    maxPersonsAmount = new BehaviorSubject<number>(12);
    languageSubscription: Subscription;
    requestPrefillData: any;
    slug: string;
    code: string;

    constructor(private $title: Title,
                private $language: LanguageService,
                private $api: ApiService){


    }

    init(){
        this.languageSubscription = this.$language.onChange.subscribe(() => {
            this.getLanding();
        });
    }

    setSlug(slug){
        this.slug = slug;
    }

    setLanding(data: any){

        // company form can be prefilled, in this case landing data in [page] property.
        if (data.request) {
            this.requestPrefillData = data.request;
            data = data.page;
        }

        if (this.landing) {
            this.landing.setData(data);
        } else {
            this.landing = new Landing(data);
        }
        this.setTitle();
        this.onLandingChange.next(this.landing);
    }

    setTitle(){
        this.$title.setTitle('3M ' + this.landing.title);
    }

    getLanding(): Promise<Landing>{
        if (!this.code) {
            return this.$api.get(`pages${this.slug}.json`).then((data: any) => {
                this.setLanding(data);
                return this.landing;
            });
        } else {
            return this.getSecuredLanding();
        }
    }

    getSecuredLanding(): Promise<Landing>{
        return this.$api.post(`pages${this.slug}.json`, {code: this.code}).then((data: any) => {
            this.setLanding(data);
            return this.landing;
        });
    }

    destroy(){
        this.landing = null;
        this.code = null;
        this.slug = null;
        this.languageSubscription.unsubscribe();
    }
}
