<div class="main-title">
    <div class="container">
        <h1 *ngIf="home && home.title" class="heading -h1" [innerHtml]="home.title">
        </h1>
    </div>
</div>
<div class="main-content">
    <div class="container">
        <main class="text-content">
            <p *ngIf="home && home.topContent" [innerHtml]="home.topContent"></p>
            <br>
            <ul *ngIf="home && home.topBlocks && home.topBlocks.length" class="landing-list">
                <li class="landing-overview" *ngFor="let block of home.topBlocks">
                    <a [href]="block.url" [target]="block.isNewTab ? '_blank' : '_self'">
                        <figure class="landing-overview_img" [ngStyle]="{'background-image':'url(' + block.image + ')'}"></figure>
                        <span [innerHtml]="block.title"></span>
                    </a>
                </li>
            </ul>
            <p *ngIf="home && home.middleContent" [innerHtml]="home.middleContent"></p>
            <br>
            <ul *ngIf="home && home.bottomBlocks && home.bottomBlocks.length" class="landing-list">
                <li class="landing-overview" *ngFor="let block of home.bottomBlocks">
                    <a [href]="block.url" [target]="block.isNewTab ? '_blank' : '_self'">
                        <figure class="landing-overview_img" [ngStyle]="{'background-image':'url(' + block.image + ')'}"></figure>
                        <span [innerHtml]="block.title"></span>
                    </a>
                </li>
            </ul>
            <p *ngIf="home && home.bottomContent" [innerHtml]="home.bottomContent"></p>
        </main>
    </div>
</div>

