/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-404.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./page-404.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../_services/language.service";
var styles_Page404Component = [i0.styles];
var RenderType_Page404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Page404Component, data: {} });
export { RenderType_Page404Component as RenderType_Page404Component };
export function View_Page404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "page-404"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "heading page-404_heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "page-404_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("messages.404_header")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("messages.404_body")); _ck(_v, 5, 0, currVal_1); }); }
export function View_Page404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-404", [], null, null, null, View_Page404Component_0, RenderType_Page404Component)), i1.ɵdid(1, 114688, null, 0, i3.Page404Component, [i2.TranslateService, i4.Title, i5.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Page404ComponentNgFactory = i1.ɵccf("app-page-404", i3.Page404Component, View_Page404Component_Host_0, {}, {}, []);
export { Page404ComponentNgFactory as Page404ComponentNgFactory };
