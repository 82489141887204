import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { LanguageService } from "../../_services/language.service";

@Component({
    selector: 'app-page-404',
    templateUrl: 'page-404.component.html',
    styleUrls: ['page-404.component.scss']
})
export class Page404Component implements OnInit {

    constructor(public translate: TranslateService, public $title: Title, public $language: LanguageService){
    }

    ngOnInit(){
        this.translate.get('messages.404_body').subscribe((data => {
            this.$title.setTitle('3M ' + data);
        }));

        this.$language.onChange.subscribe((test) => {
            this.$title.setTitle('3M ' + this.translate.instant('messages.404_body'));
        });

    }

}
