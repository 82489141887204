/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./select.component";
var styles_SelectComponent = [i0.styles];
var RenderType_SelectComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SelectComponent, data: {} });
export { RenderType_SelectComponent as RenderType_SelectComponent };
function View_SelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "select-choice-option"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "select-choice-option_close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOptionRemove($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 1, 0, currVal_0); }); }
function View_SelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "select_choice"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedOptions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SelectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "select-dropdown_item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "-selected": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "select-option"]], null, [[null, "click"], [null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOptionClick($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.onOptionHover(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "-hover": 0 }), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "select-dropdown_item"; var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit.selected); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "select-option"; var currVal_3 = _ck(_v, 5, 0, ((_v.context.$implicit == _co.hoveredOption) && !_co.isTouchDevice)); _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.text; _ck(_v, 6, 0, currVal_4); }); }
function View_SelectComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "select-dropdown_empty"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emptyText; _ck(_v, 1, 0, currVal_0); }); }
function View_SelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["dropdown", 1]], null, 5, "div", [["class", "select-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "select-dropdown_list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_5)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showedOptions; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.showedOptions.length && _co.emptyText); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SelectComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), i1.ɵqud(671088640, 2, { dropdown: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "select"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["input", 1]], null, 2, "input", [["autocomplete", "off"], ["class", "select_input"], ["type", "text"]], [[8, "disabled", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (_co.onSelectClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "-selected": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "select_arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "-opened": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isMultiple && _co.selectedOptions.length); _ck(_v, 4, 0, currVal_0); var currVal_2 = "select_input"; var currVal_3 = _ck(_v, 7, 0, _co.selectedOptions.length); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = "select_arrow"; var currVal_5 = _ck(_v, 10, 0, _co.dropdownIsOpened); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = (_co.dropdownIsOpened && _co.options.length); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isDisabled; _ck(_v, 5, 0, currVal_1); }); }
export function View_SelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select", [], null, [["document", "click"], ["document", "keyup"], ["document", "keypress"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onOutsideClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keyup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onKeyup($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keypress" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeypress($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_SelectComponent_0, RenderType_SelectComponent)), i1.ɵdid(1, 573440, null, 0, i3.SelectComponent, [i1.ElementRef], null, null)], null, null); }
var SelectComponentNgFactory = i1.ɵccf("app-select", i3.SelectComponent, View_SelectComponent_Host_0, { items: "options", isDisabled: "disabled", selectedItems: "selected", placeholder: "placeholder", textProperty: "textProperty", emptyText: "emptyText", isMultiple: "multiple" }, { onChange: "onChange" }, []);
export { SelectComponentNgFactory as SelectComponentNgFactory };
