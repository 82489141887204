import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from "@angular/router";

@Component({
    selector: 'app-page-message',
    templateUrl: './page-message.component.html',
    styleUrls: ['./page-message.component.scss']
})
export class PageMessageComponent implements OnInit {
    state: string;
    type: string;

    constructor(private route: ActivatedRoute,
                private router: Router){
    }

    ngOnInit(){
        this.route.data
            .subscribe(
                (params) =>{
                    this.state = params.data['state'];
                    this.type = params.data['type'];
                }
            )
    }

}
