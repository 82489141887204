import { Workshop } from "./workshop.model";
import { Country } from "./country.model";
import { Question } from "./question.model";

export class Landing {
    // Preview data
    countries: Country[] = [];
    courseLocations: any[] = [];
    division: string;
    id: string;
    isAfterwardsPaymentEnabled: boolean;
    isFullSubscription: boolean;
    isVatRequired: boolean;
    leftDescriptionImageUrl: string;
    leftDescriptionText: string;
    rightDescriptionTopImageUrl: string;
    rightDescriptionText: string;
    rightDescriptionBottomImageUrl: string;
    logoUrl: string;
    private: boolean;
    receiverEmail: null;
    title: string;
    // Secured data
    footer: string;
    infoQuestionPlaceholder: string;
    peopleAmountPlaceholder: string;
    practicalInfo: string;
    questions: Question[] = [];
    terms: string;
    workshops: Workshop[] = [];


    constructor(data: any) {
        this.setData(data);
    }

    setData(data: any) {
        // Preview data
        this.countries = Country.collection(data.countries);
        this.courseLocations = data.course_locations;
        this.division = data.division;
        this.id = data.id;
        this.isAfterwardsPaymentEnabled = data.is_afterwards_payment_enabled || false;
        this.isFullSubscription = (typeof data.is_full_subscription === 'undefined' || data.is_full_subscription);
        this.isVatRequired = data.is_vat_required;
        this.leftDescriptionImageUrl = data.left_description_image_url;
        this.leftDescriptionText = data.left_description_text;
        this.rightDescriptionTopImageUrl = data.right_description_top_image_url;
        this.rightDescriptionText = data.right_description_text;
        this.rightDescriptionBottomImageUrl = data.right_description_bottom_image_url;
        this.logoUrl = data.logo_url;
        this.receiverEmail = data.receiver_email;
        this.title = data.title;
        this.private = data.private;
        // Secured data
        this.footer = data.footer;
        this.infoQuestionPlaceholder = data.info_question_placeholder;
        this.peopleAmountPlaceholder = data.people_amount_placeholder;
        this.practicalInfo = data.practical_info;
        this.questions = Question.collection(data.questions);
        this.terms = data.terms;
        this.workshops = Workshop.collection(data.workshops);
    }
}
