import { Component } from '@angular/core';
import { AccessService } from "../../_services/access.service";
import { LandingService } from "../../_services/landing.service";
import { Landing } from "../../_models/landing.model";

@Component({
    selector: '[app-content]',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent {
    landing: Landing;
    showLanding = true;

    constructor(public $access: AccessService,
                public $landing: LandingService){

        this.setLanding();
        this.initAccessSubscription();
    }

    setLanding(){
        this.landing = this.$landing.landing;
        this.showLanding = this.$access.onAccessed.value;
    }

    initAccessSubscription(){
        this.$access.onAccessed.subscribe(() => {
            this.setLanding();
        });
    }
}
