export class Answer {
    id: string;
    question: string;
    text: string;

    constructor(data){
        this.id = data.id;
        this.question = data.question;
        this.text = data.option_text;
    }
}