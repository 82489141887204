import { Component, OnInit } from '@angular/core';
import { LogoService } from "./logo.service";

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
    constructor(public $logo: LogoService){
    }

    ngOnInit(){

    }
}
