<h2 class="heading -h5">
    {{'headers.additional_info' | translate}}
</h2>
<div [formGroup]="metaFormGroup">
    <div class="form-line" *ngIf="question">
        <div class="form-group">
            <input type="hidden" formControlName="answers">
            <app-select [options]="answers"
                        [multiple]="true"
                        emptyText="No results"
                        [placeholder]="question.text"
                        (onChange)="onAnswerSelected($event)"></app-select>
        </div>
    </div>
    <div class="form-line">
        <div class="form-group -column">
            <label class="checkbox">
                <input formControlName="terms" type="checkbox">
                <span class="checkbox_square"></span>
                <span [innerHtml]="(slug === '/oralcare' ? 'labels.terms_oralcare' : 'labels.terms') | translate: {domain: apiUrl}"></span>
            </label>
            <span *ngIf="!metaFormGroup.get('terms').valid" class="form-error-msg">
                {{'errors.accept_terms'| translate}}
            </span>
        </div>
    </div>
    <div class="form-line">
        <div class="form-group -column">
            <label class="checkbox">
                <input formControlName="privacyPolicy" type="checkbox">
                <span class="checkbox_square"></span>
                <span [innerHtml]="'labels.privacy_policy' | translate:{domain: apiUrl}">
                </span>
            </label>
            <span *ngIf="!metaFormGroup.get('privacyPolicy').valid" class="form-error-msg">
                {{'errors.accept_privacy_policy'| translate}}
            </span>
        </div>
    </div>
    <br>
    <div class="form-line">
        <div class="form-group">
            <input formControlName="discount" [placeholder]="'fields.discount_code' | translate" type="text">
        </div>
        <div class="form-group"></div>
    </div>
</div>
