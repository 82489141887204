import { NgModule } from "@angular/core";
import { AccessFormComponent } from "./access-form.component";

import { ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "../shared.module";



@NgModule({
    declarations: [
        AccessFormComponent
    ],
    imports: [
        ReactiveFormsModule,
        SharedModule
    ],
    exports: [
        AccessFormComponent
    ]
})

export class AccessFormModule{}