export class HomePageBlock {
    url: string;
    title: string;
    image: string;
    isNewTab: boolean;

    constructor(data) {
        this.url = data.url;
        this.title = data.title;
        this.image = data.image_url;
        this.isNewTab = data.is_new_tab;
    }
}
