<h2 class="heading -h5">
    {{'headers.training_signup' | translate}}:
</h2>
<form [formGroup]="form" [ngClass]="{'-submitted': form['submitted']}" class="main-form">
    <app-workshop-table></app-workshop-table>
    <div class="main-form_content">
        <h2 class="heading -h5">
            {{'headers.company_info' | translate}}
        </h2>
        <app-company></app-company>
        <app-persons></app-persons>
        <app-addition></app-addition>

        <button [disabled]="showPaymentOptions" class="btn-primary -large -arrow main-form_submit-btn" type="button" (click)="onSubmit()">

            {{'buttons.training_signup' | translate}}
        </button>
        <div *ngIf="showPaymentOptions" [@slideToggle]>
            <app-payment (onSubmit)="onSubmit()"></app-payment>
        </div>
        <p class="main-form_error-msg" *ngIf="!form.valid && this.form['submitted']">
            {{'errors.fill_required' | translate}}
        </p>
    </div>
</form>

