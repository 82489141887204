import { Component, Input, OnInit } from '@angular/core';
import { Workshop } from "../_models/workshop.model";
import { LandingService } from "../_services/landing.service";

@Component({
    selector: 'app-workshops',
    templateUrl: './workshops.component.html',
    styleUrls: ['./workshops.component.scss']
})
export class WorkshopsComponent implements OnInit {
    @Input('workshops') workshops: Workshop[];

    constructor(private $landing: LandingService){
    }

    ngOnInit(){
    }
}

