import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { trigger, style, animate, transition, state } from '@angular/animations';
import { LanguageService } from "../../_services/language.service";
import { Workshop } from "../../_models/workshop.model";
import * as moment from 'moment';
import { FormService } from "../_services/form.service";
import { LandingService } from "../../_services/landing.service";
import { Landing } from "../../_models/landing.model";
import { Country } from "../../_models/country.model";

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    animations: [
        trigger('slideToggle', [
            state('*', style({
                height: '*',
                opacity: 1
            })),
            state('void', style({
                height: 0,
                opacity: 0
            })),
            transition('void <=> *', animate(300))
        ]),
    ]
})
export class CompanyComponent implements OnInit {
    formGroup: FormGroup;
    @Input('isExtended') isExtended: boolean = false;
    landing: Landing;
    isBillingAddress: boolean = false;
    selectedCountry: Country;
    peopleAmountPlaceholder = null;
    infoQuestionPlaceholder = null;
    vatMask = {
        legal: '',
        billing: ''
    };
    slug = '';

    // datepicker

    dates: any[] = [new FormControl()];

    constructor(private $form: FormService,
                private $landing: LandingService,
                public $language: LanguageService){


        this.setLanding();
        this.setPlaceholders();

        $language.onChange.subscribe(() => {
            this.resetCountries();

            if (this.isExtended) {
                this.resetWorkshops();
            }
        });

        this.$landing.onLandingChange.subscribe((landing: Landing) => {
            this.setLanding();
            this.setPlaceholders();
        });

        this.slug = this.$landing.slug;
    }

    ngOnInit(){
        this.defineFormGroup();
        this.prefillFormData();
    }

    setLanding(){
        this.landing = this.$landing.landing;
    }

    prefillFormData(){
        let prefillData = this.$landing.requestPrefillData;
        if (prefillData) {
            this.patchCompanyFormGroup(prefillData);
        }
    }

    resetWorkshops(){
        this.formGroup.get('workshops').setValue('');
    }

    resetCountries(){
        if (this.isExtended) {
            this.formGroup.get('country').setValue('');
        } else {
            this.formGroup.get('legal.country').setValue('');
            this.formGroup.get('legal.VATNumber').setValidators(this.vatValidation());
            this.formGroup.get('legal.VATNumber').updateValueAndValidity();

            if (this.isBillingAddress) {
                this.formGroup.get('billing.country').setValue('');
                this.formGroup.get('billing.VATNumber').setValidators(this.vatValidation());
                this.formGroup.get('billing.VATNumber').updateValueAndValidity();
            }
        }
    }

    setPlaceholders(){
        this.peopleAmountPlaceholder = this.landing.peopleAmountPlaceholder;
        this.infoQuestionPlaceholder = this.landing.infoQuestionPlaceholder;
    }

    onSelectCountry(country, addressType?){
        this.updateVatField(country, addressType);
        this.manageCustomField(country);
    }

    manageCustomField(country){
        if (this.slug === '/oralcare') {
            (<FormGroup>this.formGroup.get('legal')).removeControl('meta');

            if (country.code === 'NL') {
                (<FormGroup>this.formGroup.get('legal')).addControl('meta', new FormGroup({
                    bigNumber: new FormControl('')
                }));
            }

            if (country.code === 'BE') {
                (<FormGroup>this.formGroup.get('legal')).addControl('meta', new FormGroup({
                    rizivNumber: new FormControl('')
                }));
            }
        }
    }

    updateVatField(country: Country, addressType?){
        let countryControl = addressType ? this.formGroup.get(addressType).get('country') : this.formGroup.get('country');
        let vatControl = addressType ? this.formGroup.get(addressType).get('VATNumber') : this.formGroup.get('VATNumber');
        countryControl.setValue(country.code);

        if (vatControl) {
            let vatMask = {
                'NL': '(000000000B01)',
                'BE': '(0000000001)',
                'LU': '(0000000001)'
            };

            this.vatMask[addressType] = vatMask[country.code];

            let validators = [Validators.pattern(country.vatNumberFormat)];

            if (this.landing.isVatRequired) {
                validators.push(Validators.required);
            }

            vatControl.setValidators(validators);
            vatControl.updateValueAndValidity();
        }
    }


    onSelectWorkshop(workshops: Workshop[]){
        let workshopIdList = workshops.map(workshop => workshop.id);
        this.formGroup.get('workshops').setValue(workshopIdList);
    }

    onSelectDates(){
        let datesList = [];

        this.dates.forEach(date => {
            date.value && datesList.push(moment(date.value).format('YYYY-MM-DD'));
        });
        this.formGroup.get('dates').setValue(datesList);
    }

    addDate(){
        this.dates.push(new FormControl());
    }

    datesValidation(){
        let isDateSelected = this.dates.some((date) => {
            return date.value;
        });

        return isDateSelected ? null : {'dates': true};

    }

    defineFormGroup(){
        // console.log(this.isExtended);
        if (this.isExtended) {
            this.formGroup = this.defineExtendedFormGroup();
        } else {
            this.formGroup = new FormGroup({
                legal: this.defineCompanyFormGroup()
            });
        }
        this.$form.form.setControl('company', this.formGroup);

        // console.log(this.formGroup);
    }

    defineExtendedFormGroup(){
        return new FormGroup({
            page: new FormControl(this.landing.id, Validators.required),
            name: new FormControl('', Validators.required),
            bookerFirstName: new FormControl('', Validators.required),
            bookerMiddleName: new FormControl(''),
            bookerLastName: new FormControl('', Validators.required),
            bookerGender: new FormControl('', Validators.required),
            bookerPosition: new FormControl('', Validators.required),
            bookerEmail: new FormControl('', [Validators.required, Validators.pattern(this.$form.emailRegex)]),
            bookerPhoneNumber: new FormControl('', Validators.required),
            street: new FormControl('', Validators.required),
            building: new FormControl('', Validators.required),
            postalCode: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            country: new FormControl('', Validators.required),
            workshops: new FormControl(null, Validators.required),
            dates: new FormControl('', this.datesValidation.bind(this)),
            location: new FormControl(this.landing.courseLocations[0].id, Validators.required),
            peopleAmount: new FormControl(''),
            infoQuestion: new FormControl(''),
        })
    }

    defineCompanyFormGroup(){
        return new FormGroup({
            name: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.pattern(this.$form.emailRegex)]),
            phoneNumber: new FormControl('', Validators.required),
            VATNumber: new FormControl('', this.vatValidation()),
            street: new FormControl('', Validators.required),
            building: new FormControl('', Validators.required),
            postalCode: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            country: new FormControl([], Validators.required)
        });

    }

    vatValidation(){
        return this.landing.isVatRequired ? Validators.required : null;
    }

    patchCompanyFormGroup(patchData){
        this.formGroup.get('legal').patchValue({
            name: patchData.name,
            email: patchData.booker_email,
            phoneNumber: patchData.booker_phone_number,
            street: patchData.street,
            building: patchData.building,
            postalCode: patchData.postal_code,
            city: patchData.city,
            country: patchData.country
        });
        this.selectedCountry = this.landing.countries.find(country => patchData.country === country.code);
        this.onSelectCountry(this.selectedCountry, 'legal');
    }


    toggleBillingAddress(){
        this.isBillingAddress = !this.isBillingAddress;
        this.isBillingAddress
            ? this.formGroup.addControl('billing', this.defineCompanyFormGroup())
            : this.formGroup.removeControl('billing');
    }

}
