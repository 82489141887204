<div class="landing-logo" *ngIf="$logo.logoSrc; else defaultLogo">
    <img [src]="$logo.logoSrc" alt="">
</div>
<ng-template #defaultLogo>
    <div class="header_logo" *ngIf="$logo.logoLink === '/'; else blankLogoLink">
        <a [routerLink]="$logo.logoLink">
            <img class="logo-image" src="../../assets/img/3m-logo.png" srcset="../../assets/img/3m-logo_2x.png 2x" alt="">
            <img class="logo-image -mobile" src="../../assets/img/3m-logo-mobile.png" srcset="../../assets/img/3m-logo-mobile_2x.png 2x" alt="">
        </a>
    </div>
    <ng-template #blankLogoLink>
        <div class="header_logo">
            <a target="_blank" [href]="$logo.logoLink">
                <img class="logo-image" src="../../assets/img/3m-logo.png" srcset="../../assets/img/3m-logo_2x.png 2x" alt="">
                <img class="logo-image -mobile" src="../../assets/img/3m-logo-mobile.png" srcset="../../assets/img/3m-logo-mobile_2x.png 2x" alt="">
            </a>
        </div>
    </ng-template>
</ng-template>
