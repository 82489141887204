import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccessService } from "../_services/access.service";

@Component({
    selector: 'app-access-form',
    templateUrl: './access-form.component.html',
    styleUrls: ['./access-form.component.scss']
})
export class AccessFormComponent {

    form: FormGroup;
    isErrorShowed = false;

    constructor(private $access: AccessService){
        this.initFrom();
    }

    initFrom(){
        this.form = new FormGroup({
            accessCode: new FormControl('', Validators.required)
        });
    }

    submit(){
        this.form['submitted'] = true;

        if (this.form.valid) {
            this.$access.getAccessWithCode(this.form.value.accessCode).catch(() => {
                this.isErrorShowed = true;
            });
        }
    }
}
