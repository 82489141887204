import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable()
export class PaymentService {

    constructor(private _api: ApiService){
    }

    submitForm(data){
        return new Promise((resolve, reject) =>{
            this._api.post('subscription.json', data).then(response =>{
                resolve(response);
            }).catch(() =>{
                reject();
            })
        });
    }

    redirectToGateway(url: string){
        window.location.href = url.toString();
    }
}
