import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Language } from "../core/header/language-select/language.model";
import { ApiService } from "./api.service";
import { Subject } from "rxjs";


@Injectable()
export class LanguageService {
    selected: Language;
    languages: Language[] = [];
    onChange: Subject<string> = new Subject<string>();

    constructor(private $translate: TranslateService, private $api: ApiService){
    }

    initLanguage() {
        const storedLanguageCode = localStorage.getItem('language');
        if (storedLanguageCode) {
            this.setLanguage(storedLanguageCode);
        } else {
            this.setDefault();
        }
    }

    setDefault() {
        let defaultLanguage = 'nl';
        const browserLanguage = window.navigator.language;
        if (browserLanguage.indexOf('fr') > -1) {
            defaultLanguage = 'fr';
        }
        this.$translate.setDefaultLang(defaultLanguage);
        this.setLanguage(defaultLanguage);
    }

    setLanguage(languageCode: string){
        this.selected = this.languages.find(language => language.code === languageCode);
        this.$api.setLanguage(languageCode);
        localStorage.setItem('language', languageCode);
        this.$translate.use(languageCode).subscribe(() => {
            this.onChange.next(languageCode);
        });
    }

    fetchLanguages(){
        return this.$api.get('languages.json').then((data: any[]) => {
            this.languages = data.map(item => new Language(item));
        });
    }
}
