
<div class="workshop-table" id="workshopTable">
    <div class="workshop-table_header">
        <button class="workshop-table_order-btn workshop-cell"
                [ngClass]="{'-active': orderExpression === 'dateStart', '-reverse': orderReverse}"
                (click)="orderTableBy('dateStart')">
            {{'workshop_table.date' | translate}}
        </button>
        <button class="workshop-table_order-btn workshop-cell"
                [ngClass]="{'-active': orderExpression === 'timeStart', '-reverse': orderReverse}"
                (click)="orderTableBy('timeStart')">
            {{'workshop_table.time' | translate}}
        </button>
        <button class="workshop-table_order-btn workshop-cell -large"
                [ngClass]="{'-active': orderExpression === 'shortTitle', '-reverse': orderReverse}"
                (click)="orderTableBy('shortTitle')">
            {{'workshop_table.workshop' | translate}}
        </button>
        <button class="workshop-table_order-btn workshop-cell"
                    [ngClass]="{'-active': orderExpression === 'price', '-reverse': orderReverse}"
                (click)="orderTableBy('price')">
            {{'workshop_table.price' | translate}}<br>
            {{'workshop_table.vat_excluded' | translate}}
        </button>
        <button class="workshop-table_order-btn workshop-cell"
                [ngClass]="{'-active': orderExpression === 'seatsAvailable', '-reverse': orderReverse}"
                (click)="orderTableBy('seatsAvailable')">
            {{'workshop_table.available' | translate}}
        </button>
        <button class="workshop-table_order-btn workshop-cell"
                [ngClass]="{'-active': orderExpression === 'city', '-reverse': orderReverse}"
                (click)="orderTableBy('city')">
            {{'workshop_table.location' | translate}}
        </button>
    </div>
    <ul class="workshop-table_body">
        <li *ngFor="let training of trainings | orderBy: orderExpression : orderReverse | restIsFull"
            [ngClass]="{'-selected': training.selected,
            '-highlighted': training.workshopId === highlightedWorkshop,
            '-disabled': selectedCountry && training.country !== selectedCountry}"
            class="workshop-row"
            (click)="onSelect(training)">
            <span class="checkbox">
                <input type="checkbox" [(ngModel)]="training.selected">
                <span class="checkbox_square"></span>
            </span>
            <span class="workshop-cell -left">
                {{'weekdays.' + training.startWeekday | translate}}
                <br>
                {{training.dateStart | date: 'dd-MM-yyyy'}}
                <ng-container *ngIf="training.dateStart.getTime() !== training.dateEnd.getTime()">
                    <br>
                    {{'weekdays.' + training.endWeekday | translate}}
                    <br>
                    {{training.dateEnd | date: 'dd-MM-yyyy'}}
                </ng-container>
            </span>
            <span class="workshop-cell -right">
                {{training.timeStart}}
                <br>
                {{training.timeEnd}}
            </span>
            <span class="workshop-cell -left  -large">
                {{training.shortTitle}}
            </span>
            <span class="workshop-cell -right -nowrap">
                € {{training.price}}
                <ng-container *ngIf="training.priceType === 0">
                    ,- p.p
                </ng-container>
                <ng-container *ngIf="training.priceType === 1">
                    ,- p.t
                </ng-container>
            </span>
            <span class="workshop-cell -left">
                {{training.seatsAvailable}} {{'workshop_table.places' | translate}}
            </span>
            <span class="workshop-cell -right">
                {{training.city}}
            </span>
        </li>
    </ul>
    <span class="form-error-msg" *ngIf="workshopsFormArray.hasError('required')">
        {{'errors.select_workshop' | translate}}
    </span>
</div>
