/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./language-select.component";
import * as i4 from "../../../_services/language.service";
var styles_LanguageSelectComponent = [i0.styles];
var RenderType_LanguageSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageSelectComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 0, name: "*", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void <=> *", animation: { type: 4, styles: null, timings: 150 }, options: null }], options: {} }] } });
export { RenderType_LanguageSelectComponent as RenderType_LanguageSelectComponent };
function View_LanguageSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "language-select_flag"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "language-select_name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.flag; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_LanguageSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "language-select_list"]], [[24, "@fade", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageSelectComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.remainingLanguages; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelectOpened; _ck(_v, 0, 0, currVal_0); }); }
function View_LanguageSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "language-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "language-select_btn btn-pure"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "language-select_flag"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "language-select_name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageSelectComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isSelectOpened; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLanguage.flag; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.currentLanguage.name; _ck(_v, 4, 0, currVal_1); }); }
export function View_LanguageSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageSelectComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LanguageSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-language-select", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).closeSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LanguageSelectComponent_0, RenderType_LanguageSelectComponent)), i1.ɵdid(1, 49152, null, 0, i3.LanguageSelectComponent, [i1.ElementRef, i4.LanguageService], null, null)], null, null); }
var LanguageSelectComponentNgFactory = i1.ɵccf("app-language-select", i3.LanguageSelectComponent, View_LanguageSelectComponent_Host_0, {}, {}, []);
export { LanguageSelectComponentNgFactory as LanguageSelectComponentNgFactory };
