<section class="access-form">
    <h2 class="heading -h5">
        {{'headers.code_required' | translate}}
    </h2>
    <form [formGroup]="form" [ngClass]="{'-submitted': form['submitted']}" (submit)="submit()">
        <div class="form-line">
            <div class="form-group">
                <input class="input -large"
                       (focus)="isErrorShowed = false"
                       [ngClass]="{'ng-invalid': isErrorShowed}"
                       formControlName="accessCode"
                       [placeholder]="'fields.access_code' | translate"
                       type="text">
                <span *ngIf="isErrorShowed" class="form-error-msg">
                    {{'errors.invalid_code' | translate}}
                </span>
            </div>
            <div class="form-group">
                <button class="btn-primary -large -arrow" type="submit">
                    {{'buttons.go_to_training' | translate}}
                </button>
            </div>
        </div>
    </form>
</section>

