import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PaymentService } from "../_services/payment.service";
import { Router } from "@angular/router";
import { ApiService } from "../_services/api.service";
import { FormService } from "./_services/form.service";
import { environment } from '../../environments/environment';
import {LandingService} from '../_services/landing.service';


@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnInit {
    form: FormGroup;
    apiUrl = environment.api;
    slug = '';

    constructor(
        private fb: FormBuilder,
        private $payment: PaymentService,
        private $form: FormService,
        private $api: ApiService,
        private router: Router,
        private $landing: LandingService
    ) {
        this.initForm();
        this.slug = this.$landing.slug;
    }


    initForm(){
        this.$form.initForm();
        this.form = this.$form.form;
        this.form.setControl('terms', new FormControl(false, Validators.requiredTrue));
        this.form.setControl('privacyPolicy', new FormControl(false, Validators.requiredTrue));
    }

    ngOnInit(){
    }

    submit(){
        this.form['submitted'] = true;
        if (this.form.valid) {
            this.submitForm();
        }
    }

    submitForm(){
        this.$api.loading.next(true);
        this.$api.post('workshop-request', JSON.stringify(this.form.value)).then(data => {
            this.router.navigate(['/message', {state: 'success', type: "information"}]);
            this.$api.loading.next(false);
        }).catch(() => {
            this.router.navigate(['/message', {state: 'error'}]);
            this.$api.loading.next(false);
        })
    }
}
