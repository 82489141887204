import { NgModule } from "@angular/core";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ContentComponent } from "./content/content.component";
import { InfoTabsComponent } from "../info-tabs/info-tabs.component";
import { TabsModule } from "../_modules/tabs/tabs.module";
import { AccessFormModule } from "../access-form/access-form.module";
import { WorkshopsModule } from "../workshops/workshops.module";
import { MainFormModule } from "../form/main-form.module";
import { SharedModule } from "../shared.module";
import { LandingComponent } from "./landing.component";
import { AccessService } from "../_services/access.service";
import { PaymentService } from "../_services/payment.service";
import { LandingService } from "../_services/landing.service";



@NgModule({
    declarations: [
        LandingComponent,
        SidebarComponent,
        ContentComponent,
        InfoTabsComponent
    ],
    imports: [
        TabsModule,
        AccessFormModule,
        WorkshopsModule,
        MainFormModule,
        SharedModule
    ],
    providers: [
        AccessService,
        LandingService,
        PaymentService
    ]
})

export class LandingModule {}