import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Injectable()
export class FormService {
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    customerReferenceNumberRegex = /^([a-zA-Z0-9 ]{1,20})$/;
    form: FormGroup;

    constructor(private fb: FormBuilder){
    }

    initFullForm(){
        this.form = this.fb.group({
            workshops: [],
            company: [],
            attendees: [],
            meta: [],
            customerReferenceNumber: new FormControl(null, Validators.pattern(this.customerReferenceNumberRegex))
        });
    }

    initForm(){
        this.form = this.fb.group({
            company: [],
            terms: [],
            privacyPolicy: []
        })
    }
}
