import { NgModule } from "@angular/core";
import { CancelComponent } from "./cancel.component";
import { CancelService } from "./cancel.service";
import { SharedModule } from "../shared.module";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        CancelComponent,
    ],
    imports: [
        FormsModule,
        SharedModule
    ],
    exports: [
    ],
    providers: [
        CancelService
    ]
})

export class CancelModule {}