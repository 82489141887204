import { Routes } from '@angular/router';
import { AccessGuard } from "./_guards/access.guard";
import { Page404Component } from "./core/page-404/page-404.component";
import { PageMessageComponent } from "./page-message/page-message.component";
import { PageMessageResolver } from "./page-message/page-message-resolver.service";
import { CancelComponent } from "./cancel/cancel.component";
import { CancelGuard } from "./_guards/cancel.guard";
import { LanguageGuard } from "./_guards/language.guard";
import { LandingComponent } from "./landing/landing.component";
import { HomeComponent } from "./home/home.component";
var ɵ0 = PageMessageResolver;
var appRoutes = [
    {
        path: '', canActivate: [LanguageGuard],
        children: [
            { path: '', component: HomeComponent },
            { path: '404', component: Page404Component },
            { path: 'message', component: PageMessageComponent, resolve: { data: ɵ0 } },
            {
                path: 'cancel', component: CancelComponent,
                children: [
                    { path: ':code', component: CancelComponent }
                ], canActivate: [CancelGuard]
            },
            {
                path: ':slug', component: LandingComponent, canActivate: [AccessGuard], children: [
                    {
                        path: 'request/:id', component: LandingComponent, canActivate: [AccessGuard]
                    }
                ]
            },
            { path: '**', redirectTo: '/404' },
        ]
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
