import { Component, OnInit } from '@angular/core';
import { TabsItem } from "../_modules/tabs/tabs.model";
import { TranslateService } from "@ngx-translate/core";
import { LandingService } from "../_services/landing.service";

@Component({
    selector: 'app-info-tabs',
    templateUrl: './info-tabs.component.html',
    styleUrls: ['./info-tabs.component.scss']
})
export class InfoTabsComponent implements OnInit {

    infoTabsItems: TabsItem[] = [];

    constructor(private $landing: LandingService,
                private $translate: TranslateService){
        this.defineTabs();

        this.$landing.onLandingChange.subscribe(() => {
            this.defineTabs();
        })
    }


    defineTabs(){
        this.infoTabsItems = [
            new TabsItem(this.$translate.instant('headers.practical_info'), this.$landing.landing.practicalInfo),
            new TabsItem(this.$translate.instant('headers.terms'), this.$landing.landing.terms)
        ]
    }

    ngOnInit(){
    }

}
