import { Component, ElementRef, OnInit } from '@angular/core';
import { Language } from "./language.model";
import { trigger, style, animate, transition, state } from '@angular/animations';
import { LanguageService } from "../../../_services/language.service";


@Component({
    selector: 'app-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss'],
    host: {
        '(document:click)': 'closeSelect($event)',
    },
    animations: [
        trigger('fade', [
            state('*', style({
                opacity: 1
            })),
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(150))
        ]),
    ]
})
export class LanguageSelectComponent {
    languages: Language[] = [];
    currentLanguage: Language;
    remainingLanguages: Language[];
    isSelectOpened: boolean = false;

    constructor(private selectRef: ElementRef, private $language: LanguageService){
        $language.onChange.subscribe(() => {
            this.assignLanguages();
        });
    }

    assignLanguages(){
        this.languages = this.$language.languages;
        this.setCurrentLanguage(this.$language.selected.code);
        this.setOptionLanguages();
    }

    setCurrentLanguage(languageCode){
        this.currentLanguage = this.languages.find((language) => language.code == languageCode);
    }

    setOptionLanguages(){
        this.remainingLanguages = this.languages.filter(language => {
            return this.currentLanguage !== language
        })
    }

    onToggle(){
        this.isSelectOpened = !this.isSelectOpened;
    }

    onSelect(language){
        this.setCurrentLanguage(language.code);
        this.setOptionLanguages();
        this.isSelectOpened = false;
        this.$language.setLanguage(language.code);
    }

    closeSelect(event){
        if (!this.selectRef.nativeElement.contains(event.target))
            this.isSelectOpened = false;
    }


}
