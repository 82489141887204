import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Subject, throwError } from "rxjs";


class ApiError {
    constructor(public status: number, public type: string, public message: string, public body: any){
    }
}

@Injectable()
export class ApiService {
    url = `${environment.api}api/`;
    headers = new HttpHeaders({'Content-type': 'application/json'});
    language = null;
    options = {headers: this.headers};
    loading = new Subject<boolean>();


    constructor(private http: HttpClient){
    }

    setOptionWithParams(params, responseType = 'json'){
        return {
            headers: this.headers,
            responseType: responseType,
            params: Object.getOwnPropertyNames(params)
                .reduce((p, key) => p.set(key, params[key]), new HttpParams())
        };
    }

    post(path, data){
        return this.http.post(`${this.url + path}`, data, this.options).pipe(
            catchError(err => this.handleErrors(err))
        ).toPromise();
    }


    get(path, params?, responseType?){
        const options = params ? this.setOptionWithParams(params, responseType) : this.options;
        let url = this.url + path;
        if (this.language) {
            url += '?_locale=' + this.language;
        }

        return this.http.get(url, options).pipe(
            catchError(err => this.handleErrors(err))
        ).toPromise();
    }

    jsonGet(path){
        return this.http.get(path, this.options).pipe(
            catchError(err => this.handleErrors(err))
        ).toPromise();
    }

    handleErrors(err){
        switch (err.status) {
            case 400: {
                break;
            }
            case 401: {
                break;
            }
            case 403 : {
                break;
            }
            case 500: {
                break;
            }
        }
        return throwError(
            new ApiError(
                err.status,
                err.error && err.error.type || err.name,
                err.error && err.error.message || err.statusText,
                err.error.errors
            )
        );
    }

    setLanguage(language){
        this.language = language;
        this.headers = new HttpHeaders({'Content-type': 'application/json', 'X-Accept-Language': language});
        this.options = ({headers: this.headers});
    }

    setToken(token){
        this.options.headers.append('Authorization', 'Bearer ' + token);
    }
}
