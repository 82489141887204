import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { LandingService } from "./landing.service";
import { BehaviorSubject } from "rxjs";
import { Landing } from "../_models/landing.model";

@Injectable()
export class AccessService {
    onAccessed = new BehaviorSubject<boolean>(false);

    constructor(private $api: ApiService,
                private $landing: LandingService){
    }

    getAccess(){
        return this.$landing.getLanding().then((landing: Landing) => {
            this.onAccessed.next(!landing.private);
        })
    }

    getAccessWithCode(code){
        this.$landing.code = code;
        return this.getAccess();
    }
}
