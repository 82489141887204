import { Workshop } from "./workshop.model";
import * as moment from "moment";

export class Training {
    // Developer data
    selected = false;

    // Training data
    id: string;
    dateStart: Date;
    dateEnd: Date;
    startWeekday: string;
    endWeekday: string;
    timeStart: string;
    timeEnd: string;
    city: string;
    country: string;
    seatsAvailable: number;
    seatsTotal: number;

    // Workshop data
    workshopId: string;
    shortTitle: string;
    price: number;
    priceType: number;
    showIfRestIsFull: boolean;

    // Cancellation data
    attendees: string[];
    subscriptionId: string;

    constructor(data: any, workshop: Workshop, cancellationData?: any){
        // Training data
        this.id = data.id;
        this.dateStart = new Date(moment(data.date_start, 'YYYY-MM-DD').format());
        this.dateEnd = new Date(moment(data.date_end, 'YYYY-MM-DD').format());
        this.startWeekday = data.start_weekday;
        this.endWeekday = data.end_weekday;
        this.timeStart = data.time_start;
        this.timeEnd = data.time_end;
        this.city = data.city;
        this.country = data.country;
        this.seatsAvailable = data.seats_available;
        this.seatsTotal = data.seats_total;

        // Workshop data
        this.price = workshop.price;
        this.workshopId = workshop.id;
        this.priceType = workshop.priceType;
        this.shortTitle = workshop.shortTitle;
        this.showIfRestIsFull = workshop.showIfRestIsFull;

        // Cancellation data
        if (cancellationData) {
            this.attendees = cancellationData.attendees;
            this.subscriptionId = cancellationData.subscriptionId;
        }
    }

    static collection(trainings: any[], workshop: Workshop): Training[]{
        if (trainings) {
            return trainings.map((training: any) => new Training(training, workshop));
        }
    }
}
