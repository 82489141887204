import { NgModule } from "@angular/core";
import { FormComponent } from "./form.component";
import { MetaComponent } from "./meta/meta.component";
import { CompanyComponent } from "./company/company.component";
import { PersonsComponent } from "./persons/persons.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { WorkshopTableComponent } from "../workshops/workshop-table/workshop-table.component";
import { RestIsFullPipe } from "../workshops/workshop-table/rest-is-full.pipe";
import { SelectModule } from "../_modules/select/select.module";
import { OrderModule } from "ngx-order-pipe";
import { SharedModule } from "../shared.module";
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullFormComponent } from "./full-form.component";
import { PaymentComponent } from "./payment/payment.component";
import { FormService } from "./_services/form.service";

@NgModule({
    declarations: [
        FormComponent,
        FullFormComponent,
        MetaComponent,
        CompanyComponent,
        PersonsComponent,
        PaymentComponent,
        WorkshopTableComponent,
        RestIsFullPipe
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        SelectModule,
        OrderModule,
        SharedModule,
        MatDatepickerModule,
        MatNativeDateModule,
        BrowserAnimationsModule
    ],
    exports: [
        FormComponent,
        FullFormComponent
    ],
    providers: [
        FormService
    ]
})

export class MainFormModule {
}