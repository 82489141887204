import { Component } from '@angular/core';
import { FormArray, FormControl, Validators, FormGroup } from "@angular/forms";
import { trigger, style, animate, transition, state } from '@angular/animations';
import { Landing } from "../../_models/landing.model";
import { LandingService } from "../../_services/landing.service";
import { FormService } from "../_services/form.service";


@Component({
    selector: 'app-persons',
    templateUrl: './persons.component.html',
    styleUrls: ['./persons.component.scss'],
    animations: [
        trigger('slideToggle', [
            state('*', style({
                height: '*',
                opacity: 1
            })),
            state('void', style({
                height: 0,
                opacity: 0
            })),
            transition('void <=> *', animate(300))
        ]),
    ]
})
export class PersonsComponent {
    personsFormArray: FormArray;
    maxPersonsAmount: number;
    slug = '';
    landing: Landing;

    constructor(private $form: FormService,
                private $landing: LandingService){
        this.setLanding();
        this.defineFormArray();
        this.setPersonsAmount();
    }

    setLanding(){
        this.landing = this.$landing.landing;
        this.slug = this.$landing.slug;
    }

    setPersonsAmount(){
        this.maxPersonsAmount = this.$landing.maxPersonsAmount.value;
        this.$landing.maxPersonsAmount.subscribe((amount: number) => {
            this.maxPersonsAmount = amount;
            this.personsFormArray.setValidators(Validators.maxLength(amount));
            this.personsFormArray.updateValueAndValidity();
        });
    }

    defineFormArray(){
        this.personsFormArray = new FormArray([
            new FormGroup({
                companyName: new FormControl('', Validators.required),
                firstname: new FormControl('', Validators.required),
                middlename: new FormControl(''),
                lastname: new FormControl('', Validators.required),
                gender: new FormControl('', Validators.required),
                email: new FormControl('', [Validators.required, Validators.pattern(this.$form.emailRegex)]),
                phoneNumber: new FormControl('')
            })
        ], Validators.maxLength(this.maxPersonsAmount));
        this.$form.form.setControl('attendees', this.personsFormArray);
    }

    removePerson(index){
        this.personsFormArray.removeAt(index);
    }

    addPerson(){
        this.personsFormArray.push(
            new FormGroup({
                companyName: new FormControl('', Validators.required),
                firstname: new FormControl('', Validators.required),
                middlename: new FormControl(''),
                lastname: new FormControl('', Validators.required),
                gender: new FormControl('', Validators.required),
                email: new FormControl('', [Validators.required, Validators.pattern(this.$form.emailRegex)]),
                phoneNumber: new FormControl('')
            })
        )
    }
}
