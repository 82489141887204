import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, Validators } from "@angular/forms";
import { FormService } from "../../form/_services/form.service";
import { LandingService } from "../../_services/landing.service";
import { Training } from "../../_models/training.model";

@Component({
    selector: 'app-workshop-table',
    templateUrl: './workshop-table.component.html',
    encapsulation: ViewEncapsulation.None
})
export class WorkshopTableComponent {
    trainings: Training[] = [];
    workshopsFormArray: FormArray;
    selectedCountry = null;
    orderExpression: string = 'dateStart';
    orderReverse: boolean = false;
    highlightedWorkshop: string;


    constructor(private $form: FormService, private $landings: LandingService){
        this.initFormArray();
        this.setTrainings();

        $landings.onLandingChange.subscribe(() => {
            this.setTrainings();
        });

        $landings.onHighlightedWorkshopChange.subscribe((workshopId) => {
            this.highlightedWorkshop = workshopId;
        });
    }

    initFormArray(){
        this.workshopsFormArray = new FormArray([], Validators.compose([Validators.required]));
        this.$form.form.setControl('workshops', this.workshopsFormArray);
    }

    orderTableBy(expression: string){
        if (expression === this.orderExpression) {
            this.orderReverse = !this.orderReverse;
        }

        this.orderExpression = expression;
    }


    setTrainings(){
        this.trainings = [];
        this.$landings.landing.workshops.forEach(workshop => {
            workshop.trainings.forEach(training => {
                this.trainings.push(training);
            })
        });
    }

    onSelect(training: Training){
        this.highlightedWorkshop = null;
        if (!this.selectedCountry || training.country === this.selectedCountry) {
            training.selected = !training.selected;

            if (training.selected) {
                this.workshopsFormArray.push(new FormControl(training.id));
                this.selectedCountry = training.country;
            } else {
                let index = this.workshopsFormArray.controls.findIndex(workshop => workshop.value == training.id);
                this.workshopsFormArray.removeAt(index);

                if (this.workshopsFormArray.length === 0) {
                    this.selectedCountry = null;
                }
            }
            this.calculateMaxAvailability();
        }
    }

    calculateMaxAvailability(){
        let availability = this.trainings.reduce((prevValue, training) => {
            return (training.selected && training.seatsAvailable < prevValue) ? training.seatsAvailable : prevValue;
        }, 8);
        this.$landings.maxPersonsAmount.next(availability);
    }
}
