<div class="select">
    <ul class="select_choice" *ngIf="isMultiple && selectedOptions.length">
        <li *ngFor="let option of selectedOptions">
            <span class="select-choice-option" *ngIf="option">
                {{option.text}}
                <span (click)="onOptionRemove($event, option)" class="select-choice-option_close"></span>
            </span>
        </li>
    </ul>
    <input (focus)="onSelectClick()"
           #input
           [disabled]="isDisabled"
           class="select_input"
           type="text"
           [ngClass]="{'-selected': selectedOptions.length}"
           autocomplete="off"/>
    <span class="select_arrow" [ngClass]="{'-opened': dropdownIsOpened}" (click)="closeDropdown()"></span>
    <div class="select-dropdown" *ngIf="dropdownIsOpened && options.length" #dropdown>
        <ul class="select-dropdown_list">
            <li class="select-dropdown_item"
                *ngFor="let option of showedOptions"
                [ngClass]="{'-selected': option.selected}">
                <span (click)="onOptionClick($event, option)"
                      (mouseover)="onOptionHover(option)"
                      [ngClass]="{'-hover': option == this.hoveredOption && !isTouchDevice}"
                      class="select-option">
                    {{option.text}}
                </span>
            </li>
        </ul>
        <span class="select-dropdown_empty" *ngIf="!showedOptions.length && emptyText">
            {{emptyText}}
        </span>
    </div>
</div>
