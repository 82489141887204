import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LanguageService } from "../../_services/language.service";
import { environment } from '../../../environments/environment';
import { FormService } from "../_services/form.service";
import { LandingService } from "../../_services/landing.service";
import { Landing } from "../../_models/landing.model";
import { Question } from "../../_models/question.model";
import { Answer } from "../../_models/answer.model";


@Component({
    selector: 'app-addition',
    templateUrl: 'meta.component.html',
    styleUrls: ['meta.component.scss']
})
export class MetaComponent implements OnInit {
    landing: Landing;
    apiUrl = environment.api;
    metaFormGroup: FormGroup;
    question: Question;
    answers: any[] = [];
    slug = '';

    constructor(
        public $landing: LandingService,
        private $form: FormService,
        private $language: LanguageService
    ) {
        this.slug = this.$landing.slug;
        this.setLanding();
        this.defineFormGroup();

        $language.onChange.subscribe(() => {
            this.metaFormGroup.get('answers').setValue(null);
            this.defineQuestionData();
        });
    }

    setLanding(){
        this.landing = this.$landing.landing;
    }

    ngOnInit(){
        this.defineQuestionData();
    }

    defineQuestionData(){
        this.question = this.landing.questions[0];

        if (this.question) {
            this.answers = this.question.options.map((option: Answer) => {
                // console.log(option);
                return {
                    text: option.text,
                    value: option.id
                };
            });
        } else {
            this.metaFormGroup.controls['answers'].setValidators(null);
        }
    }

    defineFormGroup(){
        this.metaFormGroup = new FormGroup({
            answers: new FormControl(null, Validators.required),
            terms: new FormControl(false, Validators.pattern('true')),
            privacyPolicy: new FormControl(false, Validators.pattern('true')),
            discount: new FormControl(''),
        });
        this.$form.form.setControl('meta', this.metaFormGroup);
    }

    onAnswerSelected(answers){
        if (answers.length) {
            let data = {};
            data[this.question['id']] = [];
            answers.forEach(answer => {
                data[this.question['id']].push(answer.value);
                this.metaFormGroup.controls['answers'].setValue(data);
            });
        } else {
            this.metaFormGroup.controls['answers'].setValue(null);
        }
    }

}
