import {HomePageBlock} from './home-page-block.model';

export class HomePage {
    title: string;
    topContent: string;
    middleContent: string;
    bottomContent: string;
    topBlocks: HomePageBlock[];
    bottomBlocks: HomePageBlock[];

    constructor(data) {
        this.title = data.title;
        this.topContent = data.top_content;
        this.middleContent = data.middle_content;
        this.bottomContent = data.bottom_content;
        this.topBlocks = data.top_blocks.map((block) => new HomePageBlock(block));
        this.bottomBlocks = data.bottom_blocks.map((block) => new HomePageBlock(block));
    }
}
