import { Answer } from "./answer.model";

export class Question {
    id: string;
    page: string;
    text: string;
    options: Answer[];

    constructor(data){
        this.id = data.id;
        this.page = data.page;
        this.text = data.question_text;
        this.options = data.options.map(item => new Answer(item));
    }

    static collection(questions){
        if (questions) {
            return questions.map(question => new Question(question));
        }
    }
}
