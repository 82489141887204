<h2 class="heading -h5">
    {{'headers.persons_title' | translate}}
</h2>
<div class="person "
     *ngFor="let person of personsFormArray.controls; let i = index;"
     [ngClass]="{'-excess': i + 1 > maxPersonsAmount}"
     [formGroup]="personsFormArray.controls[i]"
     [@slideToggle]>

    <h3 class="heading -h6">
        {{'headers.person' | translate}} {{i+1}}
        <button *ngIf="i != 0"
                class="btn-pure person_remove-btn"
                (click)="removePerson(i)">{{'buttons.remove' | translate}}</button>
        <span *ngIf="i + 1 > maxPersonsAmount" class="form-error-msg -side">
            {{'errors.max_persons' | translate}} {{maxPersonsAmount}}.
        </span>
    </h3>
    <div class="form-line">
        <div class="form-group">
            <input formControlName="companyName" [placeholder]="(slug === '/oralcare' ? 'fields.practice_name' : 'fields.company_name') | translate" type="text">
        </div>
    </div>
    <div class="form-line">
        <div class="form-group">
            <input formControlName="firstname" [placeholder]="'fields.first_name' | translate" type="text">
        </div>
        <div class="form-group">
            <input formControlName="middlename" [placeholder]="'fields.second_name' | translate" type="text">
        </div>
        <div class="form-group">
            <input formControlName="lastname" [placeholder]="'fields.last_name' | translate" type="text">
        </div>
    </div>
    <div class="form-line">
        <div class="form-group">
            <label class="radio">
                <input formControlName="gender" type="radio" value="1">
                <span class="radio_circle"></span>
                <span class="radio_value">{{'fields.male' | translate}}</span>
            </label>
            <label class="radio">
                <input formControlName="gender" type="radio" value="0">
                <span class="radio_circle"></span>
                <span class="radio_value">{{'fields.female' | translate}}</span>
            </label>
        </div>
        <div class="form-group">
            <input formControlName="email" [placeholder]="'fields.email' | translate" type="email">
            <span class="form-error-msg" *ngIf="personsFormArray.controls[i].get('email').value && !personsFormArray.controls[i].get('email').valid">
                {{'errors.invalid_email' | translate}}
            </span>
        </div>
        <div class="form-group">
            <input formControlName="phoneNumber" [placeholder]="'fields.phone' | translate" type="text">
        </div>
    </div>

</div>
<div class="form-line">
    <div class="form-group">
        <button *ngIf="personsFormArray.length < maxPersonsAmount" type="button" class="btn-add" (click)="addPerson()">
            {{'buttons.add_person' | translate}}
            <span class="btn-add_indicator"></span>
        </button>
    </div>
</div>
