import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { FormService } from "../_services/form.service";
import { LanguageService } from "../../_services/language.service";


@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class PaymentComponent {
    paymentFormGroup: FormGroup;
    @Output() onSubmit = new EventEmitter<any>();
    paymentOptions: { text: string, value: number }[] = [];

    constructor(private $translate: TranslateService,
                private $language: LanguageService,
                private $form: FormService){
        this.initFormGroup();
        this.setPaymentOptions();

        this.$language.onChange.subscribe(() => {
            this.setPaymentOptions();
        });
    }

    setPaymentOptions(){
        this.paymentOptions = [
            // {
            //     text: this.$translate.get('fields.direct_pay')['value'],
            //     value: 0
            // },
            {
                text: this.$translate.instant('fields.post_pay'),
                value: 1
            }
        ];
    }


    initFormGroup(){
        this.paymentFormGroup = new FormGroup({
            type: new FormControl('', Validators.required),
            terms: new FormControl(false, Validators.pattern('true'))
        });

        this.$form.form.addControl('payment', this.paymentFormGroup);
    }

    submit(){
        this.onSubmit.next();
    }
}
