<footer class="footer">
    <div class="container footer_container">
        <div class="footer_logo">
            <img class="logo-image" src="../../../assets/img/3m-logo-mobile.png" srcset="../../../assets/img/3m-logo-mobile_2x.png 2x" alt="">
        </div>
        <div class="footer_content">
            <div class="footer_menu">
                <a [href]="'footer.legal_info_url' | translate">{{'footer.legal_info' | translate}}</a>
                |
                <a [href]="'footer.privacy_url' | translate">{{'footer.privacy' | translate}}</a>
            </div>
            <div class="footer_copyright">
                &copy; 3M {{copyrightYear | date: 'yyyy' }}.  {{'footer.rights' | translate}}
            </div>
        </div>
    </div>
</footer>
