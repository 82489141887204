import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectComponent } from "./select.component";

@NgModule({
    declarations: [
        SelectComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SelectComponent,
    ],
    providers: [
    ]
})

export class SelectModule {}