/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tabs.component";
var styles_TabsComponent = [i0.styles];
var RenderType_TabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabsComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 0, name: "*", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: { type: 4, styles: null, timings: 400 }, options: null }, { type: 1, expr: "* => void", animation: { type: 4, styles: null, timings: 0 }, options: null }], options: {} }] } });
export { RenderType_TabsComponent as RenderType_TabsComponent };
function View_TabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "tab"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "-active": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "tab_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit == _co.activeItem)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
function View_TabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "text-content"]], [[24, "@fade", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit != _co.activeItem); var currVal_1 = _v.parent.context.$implicit.content; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tab-pane"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit == _co.activeItem); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "tabs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "tabs-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabsComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.items; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tabs", [], null, null, null, View_TabsComponent_0, RenderType_TabsComponent)), i1.ɵdid(1, 573440, null, 0, i3.TabsComponent, [], null, null)], null, null); }
var TabsComponentNgFactory = i1.ɵccf("app-tabs", i3.TabsComponent, View_TabsComponent_Host_0, { items: "items", activeIndex: "active" }, {}, []);
export { TabsComponentNgFactory as TabsComponentNgFactory };
