import { Component } from '@angular/core';
import { LandingService } from "../../_services/landing.service";
import { Landing } from "../../_models/landing.model";

@Component({
    selector: '[app-sidebar]',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    landing: Landing;

    constructor(public $landing: LandingService){
        this.landing = this.$landing.landing;
    }
}
