import { Pipe, PipeTransform } from '@angular/core';
import { Training } from '../../_models/training.model'

@Pipe({
    name: 'restIsFull',
    pure: false
})
export class RestIsFullPipe implements PipeTransform {

    transform(trainings: Training[]): Training[]{
        if (trainings.length === 0) {
            return trainings;
        }

        const filteredTrainings = trainings.filter((item: Training) => {
            return !item.showIfRestIsFull;
        });

        const isAvailable = filteredTrainings.reduce((previousValue, current: Training) => {
            return previousValue + current.seatsAvailable;
        }, 0) != 0;

        return isAvailable ? filteredTrainings : trainings;
    }

}