export class Country {
    code: string;
    id: string;
    name: string;
    vatNumberFormat: string;
    vatPercentage: number;

    constructor(data: any){
        this.code = data.code;
        this.id = data.id;
        this.name = data.name;
        this.vatNumberFormat = data.vat_number_format;
        this.vatPercentage = data.vat_percentage;
    }

    static collection(countries: any[]){
        return countries.map(country => new Country(country));
    }
}