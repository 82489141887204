<div class="main-title">
    <div class="container">
        <h1 class="heading -h1">
            {{landing.title}}
        </h1>
    </div>
</div>
<div class="main-content">
    <div class="container -flex">
        <aside app-sidebar></aside>
        <main app-content>
        </main>
    </div>
</div>
