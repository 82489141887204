import { Component, OnInit } from '@angular/core';
import { CancelService } from "./cancel.service";
import { ApiService } from "../_services/api.service";
import { Router } from "@angular/router";
import { Training } from "../_models/training.model";
import { Workshop } from "../_models/workshop.model";

@Component({
    selector: 'app-cancel',
    templateUrl: './cancel.component.html',
    styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {

    subscriptions: any[] = [];
    trainings: Training[] = [];
    selectedIds: string[] = [];
    submitting = false;

    constructor(private $cancel: CancelService, private $api: ApiService, private router: Router){
        this.subscriptions = $cancel.subscriptions;
        this.assignTrainings();
    }

    assignTrainings(){
        this.trainings = this.subscriptions.map((item) => {
            const attendees = [];
            if (item.attendees && item.attendees.length) {
                item.attendees.map(attendee => {
                    attendees.push(`${attendee['first_name']} ${attendee['middle_name']} ${attendee['last_name']}`);
                });
            }
            return new Training(
                item.workshop_event,
                new Workshop(item.workshop_event.workshop),
                {
                    attendees,
                    subscriptionId: item.id
                }
            );
        });
    }

    onTrainingSelect(training: Training){
        training.selected = !training.selected;
        this.updateSelectedIds();
    }

    updateSelectedIds(){
        this.selectedIds = [];
        this.trainings.forEach((item: Training) => {
            if (item.selected)
                this.selectedIds.push(item.subscriptionId);
        });
    }

    submit(){
        this.submitting = true;
        this.$api.post('cancel.json', {subscriptions: this.selectedIds, code: this.$cancel.code})
            .then(() => {
                this.submitting = false;
                this.router.navigate(['/message', {state: 'success', type: "cancellation"}]);
            }).catch(() => {
            this.submitting = false;
            this.router.navigate(['/message', {state: 'error'}]);
        })
    }

    ngOnInit(){
    }
}
