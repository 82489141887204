<h2 class="heading -h5">
    {{'headers.training_standard_signup' | translate}}:
</h2>
<form [formGroup]="form" [ngClass]="{'-submitted': form['submitted']}" class="main-form">
    <div class="main-form_content">
        <h2 class="heading -h5">
            {{'headers.company_info' | translate}}
        </h2>
        <app-company [isExtended]="true"></app-company>
        <div class="form-line">
            <div class="form-group -column">
                <label class="checkbox">
                    <input formControlName="terms" type="checkbox">
                    <span class="checkbox_square"></span>
                    <span [innerHtml]="(slug === '/oralcare' ? 'labels.terms_oralcare' : 'labels.terms') | translate: {domain: apiUrl}"></span>
                </label>
                <span *ngIf="!form.get('terms').valid" class="form-error-msg ">
                    {{'errors.accept_terms'| translate}}
                </span>
            </div>
        </div>
        <div class="form-line">
            <div class="form-group -column">
                <label class="checkbox">
                    <input formControlName="privacyPolicy" type="checkbox">
                    <span class="checkbox_square"></span>
                    <span [innerHtml]="'labels.privacy_policy' | translate:{domain:apiUrl}">
                    </span>
                </label>
                <span *ngIf="!form.get('privacyPolicy').valid" class="form-error-msg">
                    {{'errors.accept_privacy_policy'| translate}}
                </span>
            </div>
        </div>
        <br>
        <button class="btn-primary -large -arrow main-form_submit-btn" type="button" (click)="submit()">
            {{'buttons.training_submit' | translate}}
        </button>
        <p class="main-form_error-msg" *ngIf="!form.valid && this.form['submitted']">
            {{'errors.fill_required' | translate}}
        </p>
    </div>
</form>
