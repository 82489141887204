import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LanguageService } from "../_services/language.service";

@Injectable()
export class LanguageGuard implements CanActivate {
    constructor(private $language: LanguageService){
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        return this.$language.fetchLanguages().then(() => {
            this.$language.initLanguage();
            return true;
        }).catch((err) => {
            console.error('Can not load  languages');
            return false;
        })
    }
}