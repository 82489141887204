export class SelectOption {

    public text: string = '';
    public initialOption: any;
    public selected: boolean = false;

    constructor(option: any, property: string){
        this.text = option.hasOwnProperty(property) ? option[property] : option;
        this.initialOption = option;
    }
}