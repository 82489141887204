<div *ngIf="landing.rightDescriptionTopImageUrl" class="image-content">
    <img [src]="landing.rightDescriptionTopImageUrl" alt="" />
</div>
<div *ngIf="landing.rightDescriptionText" class="text-content" [innerHtml]="landing.rightDescriptionText">
</div>
<div *ngIf="landing.rightDescriptionBottomImageUrl" class="image-content">
    <img [src]="landing.rightDescriptionBottomImageUrl" alt="" />
</div>

<app-access-form *ngIf="!showLanding">
</app-access-form>

<div *ngIf="showLanding && landing">
    <div class="empty-text" *ngIf="!landing.workshops.length">
        <p>No workshops</p>
    </div>
    <div *ngIf="landing.workshops.length">
        <app-workshops [workshops]="landing.workshops"></app-workshops>
        <app-full-form *ngIf="landing.isFullSubscription"></app-full-form>
        <app-form *ngIf="!landing.isFullSubscription"></app-form>
    </div>
    <app-info-tabs></app-info-tabs>

    <footer>
        <a href="mailto:beneluxCTC@mmm.com" class="btn-dark -small">
            {{'buttons.questions' | translate}}
        </a>
        <div class="text-content" [innerHtml]="landing.footer">
        </div>
    </footer>
</div>
