import { Injectable } from '@angular/core';

@Injectable()
export class CancelService {
    subscriptions: any[];
    code: string;

    constructor(){
    }
}
