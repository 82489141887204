import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccessService } from "../_services/access.service";
import { LandingService } from "../_services/landing.service";

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(private router: Router,
                private $landing: LandingService,
                private $access: AccessService){
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        this.$landing.setSlug(state.url);
        return this.$access.getAccess().then(() => {
            return true;
        }).catch(() => {
            this.router.navigate(['/404']);
            return false;
        });
    }
}
