<div class="language-select" *ngIf="languages.length">
    <button (click)="onToggle()" class="language-select_btn btn-pure">
        <img class="language-select_flag" [src]="currentLanguage.flag" alt="">
        <span class="language-select_name">{{currentLanguage.name}}</span>
    </button>
    <ul *ngIf="isSelectOpened" [@fade]="isSelectOpened" class="language-select_list">
        <li *ngFor="let language of remainingLanguages" (click)="onSelect(language)">
            <img class="language-select_flag" [src]="language.flag" alt="">
            <span class="language-select_name">{{language.name}}</span>
        </li>
    </ul>
</div>