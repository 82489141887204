import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { PaymentService } from "../_services/payment.service";
import { Router } from "@angular/router";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { FormService } from "./_services/form.service";
import { Landing } from "../_models/landing.model";
import { ApiService } from "../_services/api.service";
import { LandingService } from "../_services/landing.service";

@Component({
    selector: 'app-full-form',
    templateUrl: './full-form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('slideToggle', [
            state('*', style({
                height: '*',
                opacity: 1
            })),
            state('void', style({
                height: 0,
                opacity: 0
            })),
            transition('void <=> *', animate(300))
        ]),
    ]
})
export class FullFormComponent {
    form: FormGroup;
    showPaymentOptions: boolean = false;
    landing: Landing;

    constructor(private fb: FormBuilder,
                private $payment: PaymentService,
                private $api: ApiService,
                private $landing: LandingService,
                private $form: FormService,
                private router: Router){
        this.setLanding();
        this.initFrom()
    }

    setLanding(){
        this.landing = this.$landing.landing;
    }

    initFrom(){
        this.$form.initFullForm();
        this.form = this.$form.form;
    }

    selectPayment(){
        this.showPaymentOptions = true;
        this.form['submitted'] = false;
    }

    onSubmit(){
        this.form['submitted'] = true;
        if (this.form.valid) {
            (!this.landing.isAfterwardsPaymentEnabled || this.showPaymentOptions)
                ? this.submitFullForm()
                : this.selectPayment()
        }
    }

    submitFullForm(){
        this.$api.loading.next(true);
        this.$payment.submitForm(JSON.stringify(this.form.value)).then((data: any) => {
            if (data.payment_type === 0 && typeof data.payment === 'object') {
                this.$payment.redirectToGateway(data.payment.payment_url);
            } else if (data.payment_type === 1) {
                this.router.navigate(['/message', {state: 'success', type: 'subscription'}]);
            } else {
                this.router.navigate(['/message', {state: 'error'}]);
            }
            this.$api.loading.next(false);
        }).catch(error => {
            this.router.navigate(['/message', {state: 'error'}]);
            this.$api.loading.next(false);
        })
    }
}
