import { Injectable } from '@angular/core';
import { LandingService } from "../../../_services/landing.service";
import { Landing } from "../../../_models/landing.model";

@Injectable()
export class LogoService {
    logoSrc: string;
    logoLinks = {
        'nl': 'https://www.3mnederland.nl/',
        'fr': 'https://www.3mbelgie.be/'
    };
    logoLink: string = "/";


    constructor(public $landing: LandingService){
        this.$landing.onLandingChange.subscribe((landing: Landing) => {
            this.logoSrc = landing.logoUrl;
        });
    }

    setLogoLink(languageCode){
        this.logoLink = this.logoLinks[languageCode];
    }
}
