import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { ApiService } from "./_services/api.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss',  './workshops/workshop-table/workshop-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    loading: boolean = false;

    constructor(public $api: ApiService){
    }

    ngOnInit() {
      this.$api.loading.subscribe(
        (isLoading) => {
          this.loading = isLoading;
        }
      );
    }

    ngOnDestroy() {
      this.$api.loading.unsubscribe();
    }
}
