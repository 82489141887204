import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from "../_services/api.service";
import { CancelService } from "../cancel/cancel.service";

@Injectable()
export class CancelGuard implements CanActivate {
    constructor(private router: Router, private $api: ApiService, private $cancel: CancelService){
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        let code = state.url.replace('/cancel', '');

        return this.$api.get('cancel' + code + '.json').then((data: any[]) =>{
            if (data.length){
                this.$cancel.subscriptions = data;
                this.$cancel.code = code.replace("/", '');
                return true;
            }else{
                this.router.navigate(['/message', {state: 'error', type: "cancellation"}]);
                return false;
            }
        }).catch(() =>{
            this.router.navigate(['/message', {state: 'error', type: "cancellation"}]);
            return false;
        });
    }
}