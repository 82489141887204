import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccessGuard } from "./_guards/access.guard";
import { Page404Component } from "./core/page-404/page-404.component";
import { PageMessageComponent } from "./page-message/page-message.component";
import { PageMessageResolver } from "./page-message/page-message-resolver.service";
import { CancelComponent } from "./cancel/cancel.component";
import { CancelGuard } from "./_guards/cancel.guard";
import { LanguageGuard } from "./_guards/language.guard";
import { LandingComponent } from "./landing/landing.component";
import { HomeComponent } from "./home/home.component";

const appRoutes: Routes = [
    {
        path: '', canActivate: [LanguageGuard],
        children: [
            {path: '', component: HomeComponent},
            {path: '404', component: Page404Component},
            {path: 'message', component: PageMessageComponent, resolve: {data: PageMessageResolver}},
            {
                path: 'cancel', component: CancelComponent,
                children: [
                    {path: ':code', component: CancelComponent}
                ], canActivate: [CancelGuard]
            },
            {
                path: ':slug', component: LandingComponent, canActivate: [AccessGuard], children: [
                    {
                        path: 'request/:id', component: LandingComponent, canActivate: [AccessGuard]
                    }
                ]
            },
            {path: '**', redirectTo: '/404'},
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        PageMessageResolver,
        AccessGuard,
        CancelGuard,
        LanguageGuard,
    ]
})

export class AppRoutingModule {

}
