import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Landing } from "../_models/landing.model";
import { LandingService } from "../_services/landing.service";

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnDestroy, OnInit {
    landing: Landing;

    constructor(public $landing: LandingService){
        this.landing = $landing.landing;
    }

    ngOnInit(){
        this.$landing.init();
    }

    ngOnDestroy(){
        this.$landing.destroy();
    }
}
