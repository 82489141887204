/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./workshops.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./workshops-accordion/workshops-accordion.component.ngfactory";
import * as i4 from "./workshops-accordion/workshops-accordion.component";
import * as i5 from "../_services/landing.service";
import * as i6 from "./workshops.component";
var styles_WorkshopsComponent = [i0.styles];
var RenderType_WorkshopsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkshopsComponent, data: {} });
export { RenderType_WorkshopsComponent as RenderType_WorkshopsComponent };
export function View_WorkshopsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "workshops"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "heading -h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ": "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-workshops-accordion", [], null, null, null, i3.View_WorkshopsAccordionComponent_0, i3.RenderType_WorkshopsAccordionComponent)), i1.ɵdid(5, 573440, null, 0, i4.WorkshopsAccordionComponent, [i5.LandingService], { workshops: [0, "workshops"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.workshops; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("headers.offered_modules")); _ck(_v, 2, 0, currVal_0); }); }
export function View_WorkshopsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workshops", [], null, null, null, View_WorkshopsComponent_0, RenderType_WorkshopsComponent)), i1.ɵdid(1, 114688, null, 0, i6.WorkshopsComponent, [i5.LandingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkshopsComponentNgFactory = i1.ɵccf("app-workshops", i6.WorkshopsComponent, View_WorkshopsComponent_Host_0, { workshops: "workshops" }, {}, []);
export { WorkshopsComponentNgFactory as WorkshopsComponentNgFactory };
