/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../access-form/access-form.component.ngfactory";
import * as i3 from "../../access-form/access-form.component";
import * as i4 from "../../_services/access.service";
import * as i5 from "../../form/full-form.component.ngfactory";
import * as i6 from "../../form/full-form.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../_services/payment.service";
import * as i9 from "../../_services/api.service";
import * as i10 from "../../_services/landing.service";
import * as i11 from "../../form/_services/form.service";
import * as i12 from "@angular/router";
import * as i13 from "../../form/form.component.ngfactory";
import * as i14 from "../../form/form.component";
import * as i15 from "../../workshops/workshops.component.ngfactory";
import * as i16 from "../../workshops/workshops.component";
import * as i17 from "@angular/common";
import * as i18 from "../../info-tabs/info-tabs.component.ngfactory";
import * as i19 from "../../info-tabs/info-tabs.component";
import * as i20 from "@ngx-translate/core";
import * as i21 from "./content.component";
var styles_ContentComponent = [i0.styles];
var RenderType_ContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentComponent, data: {} });
export { RenderType_ContentComponent as RenderType_ContentComponent };
function View_ContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.landing.rightDescriptionTopImageUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_ContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "text-content"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.landing.rightDescriptionText; _ck(_v, 0, 0, currVal_0); }); }
function View_ContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.landing.rightDescriptionBottomImageUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_ContentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-access-form", [], null, null, null, i2.View_AccessFormComponent_0, i2.RenderType_AccessFormComponent)), i1.ɵdid(1, 49152, null, 0, i3.AccessFormComponent, [i4.AccessService], null, null)], null, null); }
function View_ContentComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "empty-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No workshops"]))], null, null); }
function View_ContentComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-form", [], null, null, null, i5.View_FullFormComponent_0, i5.RenderType_FullFormComponent)), i1.ɵdid(1, 49152, null, 0, i6.FullFormComponent, [i7.FormBuilder, i8.PaymentService, i9.ApiService, i10.LandingService, i11.FormService, i12.Router], null, null)], null, null); }
function View_ContentComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form", [], null, null, null, i13.View_FormComponent_0, i13.RenderType_FormComponent)), i1.ɵdid(1, 114688, null, 0, i14.FormComponent, [i7.FormBuilder, i8.PaymentService, i11.FormService, i9.ApiService, i12.Router, i10.LandingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ContentComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-workshops", [], null, null, null, i15.View_WorkshopsComponent_0, i15.RenderType_WorkshopsComponent)), i1.ɵdid(2, 114688, null, 0, i16.WorkshopsComponent, [i10.LandingService], { workshops: [0, "workshops"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_8)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_9)), i1.ɵdid(6, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.landing.workshops; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.landing.isFullSubscription; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.landing.isFullSubscription; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ContentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_6)), i1.ɵdid(2, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_7)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-info-tabs", [], null, null, null, i18.View_InfoTabsComponent_0, i18.RenderType_InfoTabsComponent)), i1.ɵdid(6, 114688, null, 0, i19.InfoTabsComponent, [i10.LandingService, i20.TranslateService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["class", "btn-dark -small"], ["href", "mailto:beneluxCTC@mmm.com"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i20.TranslatePipe, [i20.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "text-content"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.landing.workshops.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.landing.workshops.length; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("buttons.questions")); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.landing.footer; _ck(_v, 11, 0, currVal_3); }); }
export function View_ContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_1)), i1.ɵdid(1, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_2)), i1.ɵdid(3, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_3)), i1.ɵdid(5, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_4)), i1.ɵdid(7, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContentComponent_5)), i1.ɵdid(9, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.landing.rightDescriptionTopImageUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.landing.rightDescriptionText; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.landing.rightDescriptionBottomImageUrl; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.showLanding; _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.showLanding && _co.landing); _ck(_v, 9, 0, currVal_4); }, null); }
export function View_ContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-content", ""]], null, null, null, View_ContentComponent_0, RenderType_ContentComponent)), i1.ɵdid(1, 49152, null, 0, i21.ContentComponent, [i4.AccessService, i10.LandingService], null, null)], null, null); }
var ContentComponentNgFactory = i1.ɵccf("[app-content]", i21.ContentComponent, View_ContentComponent_Host_0, {}, {}, []);
export { ContentComponentNgFactory as ContentComponentNgFactory };
