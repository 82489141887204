import { NgModule } from "@angular/core";
import { WorkshopsComponent } from "./workshops.component";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared.module";
import { WorkshopsAccordionComponent } from "./workshops-accordion/workshops-accordion.component";
import { NgxPageScrollModule } from "ngx-page-scroll";


@NgModule({
    declarations: [
        WorkshopsComponent,
        WorkshopsAccordionComponent
    ],
    imports: [
        FormsModule,
        NgxPageScrollModule,
        SharedModule
    ],
    exports: [
        WorkshopsComponent
    ]
})

export class WorkshopsModule {
}
