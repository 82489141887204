import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogoService } from "../core/header/logo/logo.service";
import { LanguageService } from "../_services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { ApiService } from "../_services/api.service";
import {HomePage} from './_models/home-page.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    changeLanguageSubscription: any;
    // landings: LandingOverview[] = [];
    // healthLandings: LandingOverview[] = [];
    home: HomePage|null = null;

    constructor(private $logo: LogoService,
                private $language: LanguageService,
                private $api: ApiService,
                public translate: TranslateService,
                public $title: Title){

        let languageCode = this.$language.selected.code;
        this.setLogo(languageCode);
        this.setLandings(languageCode);
        this.setTitle();
    }

    ngOnInit(){
        this.changeLanguageSubscription = this.$language.onChange.subscribe((languageCode: string) => {
            this.setLogo(languageCode);
            // this.setLandings(languageCode);
            this.setTitle();
            this.setHome();
        });
    }

    setLandings(languageCode){
        // this.$api.jsonGet(`./assets/json/landings/${languageCode}/main.json`).then((data: any[]) => {
        //     this.landings = data.map(item => new LandingOverview(item));
        // });
        //
        // this.$api.jsonGet(`./assets/json/landings/${languageCode}/health.json`).then((data: any[]) => {
        //     this.healthLandings = data.map(item => new LandingOverview(item));
        // });
    }

    setTitle(){
        this.translate.get('home.title').subscribe((data => {
            this.$title.setTitle('3M ' + data);
        }));
    }

    setHome() {
        this.$api.get(`home/page.json`).then((data: any) => {
            this.home = new HomePage(data);
        });
    }

    setLogo(languageCode){
        this.$logo.setLogoLink(languageCode);
    }

    ngOnDestroy(){
        this.$logo.logoLink = '/';
        this.changeLanguageSubscription.unsubscribe();
    }
}
