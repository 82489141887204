<div [formGroup]="paymentFormGroup">
    <div class="form-line">
        <div class="form-group">
            <input type="hidden" formControlName="type">
            <app-select [placeholder]="'fields.payment_method' | translate"
                        emptyText="No results"
                        [options]="paymentOptions"
                        (onChange)="paymentFormGroup.get('type').setValue($event.value)">
            </app-select>
        </div>
        <div class="form-group" >
        </div>
    </div>
    <div class="form-line">
        <div class="form-group -column">
            <label class="checkbox">
                <input type="checkbox" formControlName="terms">
                <span class="checkbox_square"></span>
                <span>
                    {{'labels.payment_terms' | translate}}
                </span>
            </label>
            <span class="form-error-msg" *ngIf="!paymentFormGroup.get('terms').valid">
                {{'errors.accept_terms'| translate}}
            </span>
        </div>
    </div>
    <button class="btn-primary -large -arrow main-form_submit-btn" type="button" (click)="submit()">
        {{'buttons.complete_order' | translate}}
    </button>
</div>